// data.js
const featureProducts = [
	{
		id: 59,
		name: "Polo T Shirt 29",
		price: "Price : $39.99",
		category: "Polo T Shirt",
		productionLeadTime: "Production Lead Time: ABC",
		color: "Color: NA",
		Sizes: "Available Size: S, M, L, XL, XXXL",
		Collar: "Collar: NA",
		minimunOrderQuantity: "Menimum Order Quantity: NA",
		Technics: "Technics: ABC,EFG,XYZ",
		Season: "Season: NA",
		fabricType: "Fabric Type: NA",
		Material: "Material: NA",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/polo t shirt/29.png"),
			require("../Images/Products/polo t shirt/29.png"),
			require("../Images/Products/polo t shirt/29.png"),
			require("../Images/Products/polo t shirt/29.png"),
			require("../Images/Products/polo t shirt/29.png"),
			require("../Images/Products/polo t shirt/29.png"),
			require("../Images/Products/polo t shirt/29.png"),
		],
		button: "Enquire",
	},
	{
		id: 11,
		name: "Plain T-Shirt 11",
		price: "Price : $39.99",
		category: "Plain T-Shirt",
		productionLeadTime: "Production Lead Time: ABC",
		color: "Color: NA",
		Sizes: "Available Size: S, M, L, XL, XXXL",
		Collar: "Collar: NA",
		minimunOrderQuantity: "Menimum Order Quantity: NA",
		Technics: "Technics: ABC,EFG,XYZ",
		Season: "Season: NA",
		fabricType: "Fabric Type: NA",
		Material: "Material: NA",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T-shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T-shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/plain t shirt/11.png"),
			require("../Images/Products/plain t shirt/11.png"),
			require("../Images/Products/plain t shirt/11.png"),
			require("../Images/Products/plain t shirt/11.png"),
			require("../Images/Products/plain t shirt/11.png"),
			require("../Images/Products/plain t shirt/11.png"),
			require("../Images/Products/plain t shirt/11.png"),
		],
		button: "Enquire",
	},
	{
		id: 103,
		name: "Printed T Shirt 28",
		price: "Price : $39.99",
		category: "Printed T Shirt",
		productionLeadTime: "Production Lead Time: ABC",
		color: "Color: NA",
		Sizes: "Available Size: S, M, L, XL, XXXL",
		Collar: "Collar: NA",
		minimunOrderQuantity: "Menimum Order Quantity: NA",
		Technics: "Technics: ABC,EFG,XYZ",
		Season: "Season: NA",
		fabricType: "Fabric Type: NA",
		Material: "Material: NA",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/printed t shirt/28.png"),
			require("../Images/Products/printed t shirt/28.png"),
			require("../Images/Products/printed t shirt/28.png"),
			require("../Images/Products/printed t shirt/28.png"),
			require("../Images/Products/printed t shirt/28.png"),
			require("../Images/Products/printed t shirt/28.png"),
			require("../Images/Products/printed t shirt/28.png"),
		],
		button: "Enquire",
	},
	{
		id: 12,
		name: "Plain T-Shirt 12",
		price: "Price : $39.99",
		category: "Plain T-Shirt",
		productionLeadTime: "Production Lead Time: ABC",
		color: "Color: NA",
		Sizes: "Available Size: S, M, L, XL, XXXL",
		Collar: "Collar: NA",
		minimunOrderQuantity: "Menimum Order Quantity: NA",
		Technics: "Technics: ABC,EFG,XYZ",
		Season: "Season: NA",
		fabricType: "Fabric Type: NA",
		Material: "Material: NA",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T-shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T-shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/plain t shirt/12.png"),
			require("../Images/Products/plain t shirt/12.png"),
			require("../Images/Products/plain t shirt/12.png"),
			require("../Images/Products/plain t shirt/12.png"),
			require("../Images/Products/plain t shirt/12.png"),
			require("../Images/Products/plain t shirt/12.png"),
			require("../Images/Products/plain t shirt/12.png"),
		],
		button: "Enquire",
	},
	{
		id: 60,
		name: "Polo T Shirt 30",
		price: "Price : $39.99",
		category: "Polo T Shirt",
		productionLeadTime: "Production Lead Time: ABC",
		color: "Color: NA",
		Sizes: "Available Size: S, M, L, XL, XXXL",
		Collar: "Collar: NA",
		minimunOrderQuantity: "Menimum Order Quantity: NA",
		Technics: "Technics: ABC,EFG,XYZ",
		Season: "Season: NA",
		fabricType: "Fabric Type: NA",
		Material: "Material: NA",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/polo t shirt/30.png"),
			require("../Images/Products/polo t shirt/30.png"),
			require("../Images/Products/polo t shirt/30.png"),
			require("../Images/Products/polo t shirt/30.png"),
			require("../Images/Products/polo t shirt/30.png"),
			require("../Images/Products/polo t shirt/30.png"),
			require("../Images/Products/polo t shirt/30.png"),
		],
		button: "Enquire",
	},
	{
		id: 68,
		name: "Short 8",
		price: "Price : $39.99",
		category: "Shorts",
		productionLeadTime: "Production Lead Time: ABC",
		color: "Color: NA",
		Sizes: "Available Size: S, M, L, XL",
		minimunOrderQuantity: "Menimum Order Quantity: NA",
		fabricType: "Fabric Type: NA",
		Material: "Material: NA",
		Customised: "Customized? YES",
		description:
			"Stay active and stylish with our Ultimate Performance Shorts, designed to deliver maximum comfort and durability for any activity. Whether you’re hitting the gym, running errands, or lounging at home, these shorts provide the perfect balance of function and fashion. Crafted from premium, moisture-wicking fabric, these shorts keep you cool and dry even during intense workouts. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			materia: "Soft, lightweight, and breathable fabric blend",
			fit: "Relaxed fit with an adjustable elastic waistband",
			durability: "Reinforced stitching for long-lasting wear",
			pockets: " Deep side pockets for secure storage of essentials like phone, keys, or wallet",
			stretchFabric: "4-way stretch fabric for maximum flexibility and comfort",
			versatileDesign: "Suitable for sports, workouts, or casual wear",
		},
		imageUrl: [
			require("../Images/Products/Shorts/8.png"),
			require("../Images/Products/Shorts/8.png"),
			require("../Images/Products/Shorts/8.png"),
			require("../Images/Products/Shorts/8.png"),
			require("../Images/Products/Shorts/8.png"),
			require("../Images/Products/Shorts/8.png"),
		],
		button: "Enquire",
	},
	{
		id: 104,
		name: "Printed T Shirt 29",
		price: "Price : $39.99",
		category: "Printed T Shirt",
		productionLeadTime: "Production Lead Time: ABC",
		color: "Color: NA",
		Sizes: "Available Size: S, M, L, XL, XXXL",
		Collar: "Collar: NA",
		minimunOrderQuantity: "Menimum Order Quantity: NA",
		Technics: "Technics: ABC,EFG,XYZ",
		Season: "Season: NA",
		fabricType: "Fabric Type: NA",
		Material: "Material: NA",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/printed t shirt/29.png"),
			require("../Images/Products/printed t shirt/29.png"),
			require("../Images/Products/printed t shirt/29.png"),
			require("../Images/Products/printed t shirt/29.png"),
			require("../Images/Products/printed t shirt/29.png"),
			require("../Images/Products/printed t shirt/29.png"),
			require("../Images/Products/printed t shirt/29.png"),
		],
		button: "Enquire",
	},
	{
		id: 69,
		name: "Short 9",
		price: "Price : $39.99",
		category: "Shorts",
		productionLeadTime: "Production Lead Time: ABC",
		color: "Color: NA",
		Sizes: "Available Size: S, M, L, XL",
		minimunOrderQuantity: "Menimum Order Quantity: NA",
		fabricType: "Fabric Type: NA",
		Material: "Material: NA",
		Customised: "Customized? YES",
		description:
			"Stay active and stylish with our Ultimate Performance Shorts, designed to deliver maximum comfort and durability for any activity. Whether you’re hitting the gym, running errands, or lounging at home, these shorts provide the perfect balance of function and fashion. Crafted from premium, moisture-wicking fabric, these shorts keep you cool and dry even during intense workouts. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			materia: "Soft, lightweight, and breathable fabric blend",
			fit: "Relaxed fit with an adjustable elastic waistband",
			durability: "Reinforced stitching for long-lasting wear",
			pockets: " Deep side pockets for secure storage of essentials like phone, keys, or wallet",
			stretchFabric: "4-way stretch fabric for maximum flexibility and comfort",
			versatileDesign: "Suitable for sports, workouts, or casual wear",
		},
		imageUrl: [
			require("../Images/Products/Shorts/9.png"),
			require("../Images/Products/Shorts/9.png"),
			require("../Images/Products/Shorts/9.png"),
			require("../Images/Products/Shorts/9.png"),
			require("../Images/Products/Shorts/9.png"),
			require("../Images/Products/Shorts/9.png"),
		],
		button: "Enquire",
	},
];

export default featureProducts;
