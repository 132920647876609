import React from "react";
import "../Category/Category.css";
import ct1 from "../Assets/Images/Category/T-shirt.jpg";
import ct2 from "../Assets/Images/Category/Printed.jpg";
import ct3 from "../Assets/Images/Category/Polo.jpg";
import ct4 from "../Assets/Images/Category/Short.jpg";
import ct5 from "../Assets/Images/Category/SweatShirt.jpg";
import ct6 from "../Assets/Images/Category/Hoodie.jpg";
import { Link } from "react-router-dom";

const Category = () => {
	return (
		<>
			<div class="album py-5 bg-body-tertiary">
				<div class="container">
					<div id="album" class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-0">
						<div class="col">
							<div class="card shadow-sm">
								<img className="rounded-top-2" src={ct1} alt="" />
								<Link to={"/Category/Plain T Shirt"}>
									<button className="btn btn-dark rounded-0">T Shirts</button>
								</Link>
							</div>
						</div>
						<div class="col">
							<div class="card shadow-sm">
								<img className="rounded-top-2" src={ct2} alt="" />
								<Link to={"/Category/Printed T Shirt"}>
									<button className="btn btn-dark rounded-0">Printed T Shirts</button>
								</Link>
							</div>
						</div>
						<div class="col">
							<div class="card shadow-sm">
								<img className="rounded-top-2" src={ct3} alt="" />
								<Link to={"/Category/Polo T Shirt"}>
									<button className="btn btn-dark rounded-0">Polo T Shirts</button>
								</Link>
							</div>
						</div>
						<div class="col">
							<div class="card shadow-sm">
								<img className="rounded-top-2" src={ct4} alt="" />
								<Link to={"/Category/Shorts"}>
									<button className="btn btn-dark rounded-0"> Shorts</button>
								</Link>
							</div>
						</div>
						<div class="col">
							<div class="card shadow-sm">
								<img className="rounded-top-2" src={ct5} alt="" />
								<Link to={"/Category/Sweat Shirt"}>
									<button className="btn btn-dark rounded-0"> Sweat Shirts</button>
								</Link>
							</div>
						</div>
						<div class="col">
							<div class="card shadow-sm">
								<img className="rounded-top-2" src={ct6} alt="" />
								<Link to={"/Category/Hoodies"}>
									<button className="btn btn-dark rounded-0">Hoodies</button>
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Category;
