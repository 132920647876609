import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Navbar from "./Components/NavBar/Navbar";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import Shop from "./Pages/Shop";
import EnquirePage from "./Pages/EnquirePage";
import Category from "./Pages/Category";
import Footer from "./Components/Footer/Footer";
import ProductDetail from "./Pages/ProductDetail";

function App() {
	return (
		<Router>
			<Navbar />
			<Routes>
				<Route exact path="/" element={<Home />} />
				<Route path="/About" element={<About />} />
				<Route path="/Contact" element={<Contact />} />
				<Route path="/shop" element={<Shop />} />
				<Route path="/Category/Plain T Shirt" element={<Category category="Plain T Shirt" />} />
				<Route path="/Category/Printed T Shirt" element={<Category category="Printed T Shirt" />} />
				<Route path="/Category/Polo T Shirt" element={<Category category="Polo T Shirt" />} />
				<Route path="/Category/Shorts" element={<Category category="Shorts" />} />
				<Route path="/Category/Hoodies" element={<Category category="Hoodies" />} />
				<Route path="/Category/Sweat Shirt" element={<Category category="Sweat Shirt" />} />
				<Route path="/EnquirePage/:productId" element={<EnquirePage />} />
				<Route path="/ProductDetail/:productId" element={<ProductDetail />} />
			</Routes>
			<Footer />
		</Router>
	);
}
export default App;
