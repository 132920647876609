import React from "react";
import img from "../Assets/Images/Hero1.jpg";
import "../Brand/Brand.css";

const Brand = () => {
	return (
		<>
			<section class="">
				<div class="container-fluid p-0">
					<section>
						<img id="about-img" className="w-100 mb-4" src={img} alt="" />
					</section>
					<div class="About d-flex flex-column gy-3 gy-md-4 gy-lg-0 align-items-lg-center">
						<div class="col-12 col-lg-6 col-xl-7">
							<div class="row justify-content-xl-center">
								<div class="col-12 col-xl-11">
									<h2 class="mb-3">Who Are We?</h2>
									<p class="lead fs-4 text-secondary mb-3">
										We help people to build incredible brands and superior products. Our perspective is to
										furnish outstanding captivating services.
									</p>
									<p class="mb-5">
										We are a fast-growing company, but we have never lost sight of our core values. We believe
										in collaboration, innovation, and customer satisfaction. We are always looking for new
										ways to improve our products and services.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Brand;
