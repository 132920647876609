// data.js
const products = [
	{
		id: 2,
		name: "Men Plain O-Neck T-Shirt 2 Light Gray",
		price: "Price : $3.8",
		category: "Plain T Shirt",
		productionLeadTime: "Production Lead Time: 30 Days",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar: O-Neck",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/plain t shirt/2.png"),
			require("../Images/Products/plain t shirt/2.png"),
			require("../Images/Products/plain t shirt/2.png"),
			require("../Images/Products/plain t shirt/2.png"),
			require("../Images/Products/plain t shirt/2.png"),
			require("../Images/Products/plain t shirt/2.png"),
		],
		button: "Enquire",
	},
	{
		id: 3,
		name: "Men Plain O-Neck T-Shirt 3 Blue Aqua",
		price: "Price : $3.8",
		category: "Plain T Shirt",
		productionLeadTime: "Production Lead Time: 30 Days",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar: O-Neck",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/plain t shirt/3.png"),
			require("../Images/Products/plain t shirt/3.png"),
			require("../Images/Products/plain t shirt/3.png"),
			require("../Images/Products/plain t shirt/3.png"),
			require("../Images/Products/plain t shirt/3.png"),
			require("../Images/Products/plain t shirt/3.png"),
		],
		button: "Enquire",
	},
	{
		id: 4,
		name: "Men Plain O-Neck T-Shirt 4 Pink",
		price: "Price : $3.8",
		category: "Plain T Shirt",
		productionLeadTime: "Production Lead Time: 30 Days",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar: O-Neck",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/plain t shirt/4.png"),
			require("../Images/Products/plain t shirt/4.png"),
			require("../Images/Products/plain t shirt/4.png"),
			require("../Images/Products/plain t shirt/4.png"),
			require("../Images/Products/plain t shirt/4.png"),
			require("../Images/Products/plain t shirt/4.png"),
		],
		button: "Enquire",
	},
	{
		id: 5,
		name: "Men Plain O-Neck T-Shirt 5 Blue Bright",
		price: "Price : $3.8",
		category: "Plain T Shirt",
		productionLeadTime: "Production Lead Time: 30 Days",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar: O-Neck",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/plain t shirt/5.png"),
			require("../Images/Products/plain t shirt/5.png"),
			require("../Images/Products/plain t shirt/5.png"),
			require("../Images/Products/plain t shirt/5.png"),
			require("../Images/Products/plain t shirt/5.png"),
			require("../Images/Products/plain t shirt/5.png"),
		],
		button: "Enquire",
	},
	{
		id: 6,
		name: "Men Plain O-Neck T-Shirt 6 Orange",
		price: "Price : $3.8",
		category: "Plain T Shirt",
		productionLeadTime: "Production Lead Time: 30 Days",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar: O-Neck",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/plain t shirt/6.png"),
			require("../Images/Products/plain t shirt/6.png"),
			require("../Images/Products/plain t shirt/6.png"),
			require("../Images/Products/plain t shirt/6.png"),
			require("../Images/Products/plain t shirt/6.png"),
			require("../Images/Products/plain t shirt/6.png"),
		],
		button: "Enquire",
	},
	{
		id: 7,
		name: "Men Plain O-Neck T-Shirt 7 Purple",
		price: "Price : $3.8",
		category: "Plain T Shirt",
		productionLeadTime: "Production Lead Time: 30 Days",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar: O-Neck",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/plain t shirt/7.png"),
			require("../Images/Products/plain t shirt/7.png"),
			require("../Images/Products/plain t shirt/7.png"),
			require("../Images/Products/plain t shirt/7.png"),
			require("../Images/Products/plain t shirt/7.png"),
			require("../Images/Products/plain t shirt/7.png"),
		],
		button: "Enquire",
	},
	{
		id: 8,
		name: "Men Plain O-Neck T-Shirt 8 White ",
		price: "Price : $3.8",
		category: "Plain T Shirt",
		productionLeadTime: "Production Lead Time: 30 Days",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar: O-Neck",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/plain t shirt/8.png"),
			require("../Images/Products/plain t shirt/8.png"),
			require("../Images/Products/plain t shirt/8.png"),
			require("../Images/Products/plain t shirt/8.png"),
			require("../Images/Products/plain t shirt/8.png"),
			require("../Images/Products/plain t shirt/8.png"),
		],
		button: "Enquire",
	},
	{
		id: 9,
		name: "Men Plain O-Neck T-Shirt 9 light Brown",
		price: "Price : $3.8",
		category: "Plain T Shirt",
		productionLeadTime: "Production Lead Time: 30 Days",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar: O-Neck",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/plain t shirt/9.png"),
			require("../Images/Products/plain t shirt/9.png"),
			require("../Images/Products/plain t shirt/9.png"),
			require("../Images/Products/plain t shirt/9.png"),
			require("../Images/Products/plain t shirt/9.png"),
			require("../Images/Products/plain t shirt/9.png"),
		],
		button: "Enquire",
	},
	{
		id: 10,
		name: "Men Plain O-Neck T-Shirt 10 Blue Mid",
		price: "Price : $3.8",
		category: "Plain T Shirt",
		productionLeadTime: "Production Lead Time: 30 Days",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar: O-Neck",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/plain t shirt/10.png"),
			require("../Images/Products/plain t shirt/10.png"),
			require("../Images/Products/plain t shirt/10.png"),
			require("../Images/Products/plain t shirt/10.png"),
			require("../Images/Products/plain t shirt/10.png"),
			require("../Images/Products/plain t shirt/10.png"),
		],
		button: "Enquire",
	},
	{
		id: 11,
		name: "Men Plain O-Neck T-Shirt 11 Blue Bright",
		price: "Price : $3.8",
		category: "Plain T Shirt",
		productionLeadTime: "Production Lead Time: 30 Days",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar: O-Neck",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/plain t shirt/11.png"),
			require("../Images/Products/plain t shirt/11.png"),
			require("../Images/Products/plain t shirt/11.png"),
			require("../Images/Products/plain t shirt/11.png"),
			require("../Images/Products/plain t shirt/11.png"),
			require("../Images/Products/plain t shirt/11.png"),
		],
		button: "Enquire",
	},
	{
		id: 12,
		name: "Men Plain O-Neck T-Shirt 12 Green Light",
		price: "Price : $3.8",
		category: "Plain T Shirt",
		productionLeadTime: "Production Lead Time: 30 Days",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar: O-Neck",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/plain t shirt/12.png"),
			require("../Images/Products/plain t shirt/12.png"),
			require("../Images/Products/plain t shirt/12.png"),
			require("../Images/Products/plain t shirt/12.png"),
			require("../Images/Products/plain t shirt/12.png"),
			require("../Images/Products/plain t shirt/12.png"),
		],
		button: "Enquire",
	},
	{
		id: 13,
		name: "Men Plain O-Neck T-Shirt 13 Light Sky Blue",
		price: "Price : $3.8",
		category: "Plain T Shirt",
		productionLeadTime: "Production Lead Time: 30 Days",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar: O-Neck",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/plain t shirt/13.png"),
			require("../Images/Products/plain t shirt/13.png"),
			require("../Images/Products/plain t shirt/13.png"),
			require("../Images/Products/plain t shirt/13.png"),
			require("../Images/Products/plain t shirt/13.png"),
			require("../Images/Products/plain t shirt/13.png"),
		],
		button: "Enquire",
	},
	{
		id: 14,
		name: "Men Plain O-Neck T-Shirt 14 Blue Navy",
		price: "Price : $3.8",
		category: "Plain T Shirt",
		productionLeadTime: "Production Lead Time: 30 Days",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar: O-Neck",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/plain t shirt/14.png"),
			require("../Images/Products/plain t shirt/14.png"),
			require("../Images/Products/plain t shirt/14.png"),
			require("../Images/Products/plain t shirt/14.png"),
			require("../Images/Products/plain t shirt/14.png"),
			require("../Images/Products/plain t shirt/14.png"),
		],
		button: "Enquire",
	},
	{
		id: 15,
		name: "Men Plain O-Neck T-Shirt 15 Dark Brown",
		price: "Price : $3.8",
		category: "Plain T Shirt",
		productionLeadTime: "Production Lead Time: 30 Days",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar: O-Neck",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/plain t shirt/15.png"),
			require("../Images/Products/plain t shirt/15.png"),
			require("../Images/Products/plain t shirt/15.png"),
			require("../Images/Products/plain t shirt/15.png"),
			require("../Images/Products/plain t shirt/15.png"),
			require("../Images/Products/plain t shirt/15.png"),
		],
		button: "Enquire",
	},
	{
		id: 16,
		name: "Men Plain O-Neck T-Shirt 16 Gray",
		price: "Price : $3.8",
		category: "Plain T Shirt",
		productionLeadTime: "Production Lead Time: 30 Days",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar: O-Neck",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/plain t shirt/16.png"),
			require("../Images/Products/plain t shirt/16.png"),
			require("../Images/Products/plain t shirt/16.png"),
			require("../Images/Products/plain t shirt/16.png"),
			require("../Images/Products/plain t shirt/16.png"),
			require("../Images/Products/plain t shirt/16.png"),
		],
		button: "Enquire",
	},
	{
		id: 17,
		name: "Men Plain O-Neck T-Shirt 17 Stone",
		price: "Price : $3.8",
		category: "Plain T Shirt",
		productionLeadTime: "Production Lead Time: 30 Days",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar: O-Neck",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/plain t shirt/17.png"),
			require("../Images/Products/plain t shirt/17.png"),
			require("../Images/Products/plain t shirt/17.png"),
			require("../Images/Products/plain t shirt/17.png"),
			require("../Images/Products/plain t shirt/17.png"),
			require("../Images/Products/plain t shirt/17.png"),
		],
		button: "Enquire",
	},
	{
		id: 18,
		name: "Men Plain O-Neck T-Shirt 18 Pine Green",
		price: "Price : $3.8",
		category: "Plain T Shirt",
		productionLeadTime: "Production Lead Time: 30 Days",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar: O-Neck",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/plain t shirt/18.png"),
			require("../Images/Products/plain t shirt/18.png"),
			require("../Images/Products/plain t shirt/18.png"),
			require("../Images/Products/plain t shirt/18.png"),
			require("../Images/Products/plain t shirt/18.png"),
			require("../Images/Products/plain t shirt/18.png"),
		],
		button: "Enquire",
	},
	{
		id: 19,
		name: "Men Plain O-Neck T-Shirt 19 Red Burgundy",
		price: "Price : $3.8",
		category: "Plain T Shirt",
		productionLeadTime: "Production Lead Time: 30 Days",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar: O-Neck",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/plain t shirt/19.png"),
			require("../Images/Products/plain t shirt/19.png"),
			require("../Images/Products/plain t shirt/19.png"),
			require("../Images/Products/plain t shirt/19.png"),
			require("../Images/Products/plain t shirt/19.png"),
			require("../Images/Products/plain t shirt/19.png"),
		],
		button: "Enquire",
	},
	{
		id: 20,
		name: "Men Plain O-Neck T-Shirt 20 Light Pink",
		price: "Price : $3.8",
		category: "Plain T Shirt",
		productionLeadTime: "Production Lead Time: 30 Days",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar: O-Neck",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/plain t shirt/20.png"),
			require("../Images/Products/plain t shirt/20.png"),
			require("../Images/Products/plain t shirt/20.png"),
			require("../Images/Products/plain t shirt/20.png"),
			require("../Images/Products/plain t shirt/20.png"),
			require("../Images/Products/plain t shirt/20.png"),
		],
		button: "Enquire",
	},
	{
		id: 21,
		name: "Men Plain O-Neck T-Shirt 21 Gray Marl",
		price: "Price : $3.8",
		category: "Plain T Shirt",
		productionLeadTime: "Production Lead Time: 30 Days",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar: O-Neck",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/plain t shirt/21.png"),
			require("../Images/Products/plain t shirt/21.png"),
			require("../Images/Products/plain t shirt/21.png"),
			require("../Images/Products/plain t shirt/21.png"),
			require("../Images/Products/plain t shirt/21.png"),
			require("../Images/Products/plain t shirt/21.png"),
		],
		button: "Enquire",
	},
	{
		id: 22,
		name: "Men Plain V-Neck T-Shirt 22 Navy Blue",
		price: "Price : $3.8",
		category: "Plain T Shirt",
		productionLeadTime: "Production Lead Time: 30 Days",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar: V-Neck",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/plain t shirt/22.png"),
			require("../Images/Products/plain t shirt/22.png"),
			require("../Images/Products/plain t shirt/22.png"),
			require("../Images/Products/plain t shirt/22.png"),
			require("../Images/Products/plain t shirt/22.png"),
			require("../Images/Products/plain t shirt/22.png"),
		],
		button: "Enquire",
	},
	{
		id: 23,
		name: "Men Plain V-Neck T-Shirt 23 Dark Blue",
		price: "Price : $3.8",
		category: "Plain T Shirt",
		productionLeadTime: "Production Lead Time: 30 Days",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar: V-Neck",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/plain t shirt/23.png"),
			require("../Images/Products/plain t shirt/23.png"),
			require("../Images/Products/plain t shirt/23.png"),
			require("../Images/Products/plain t shirt/23.png"),
			require("../Images/Products/plain t shirt/23.png"),
			require("../Images/Products/plain t shirt/23.png"),
		],
		button: "Enquire",
	},
	{
		id: 24,
		name: "Men Plain V-Neck T-Shirt 24 White",
		price: "Price : $3.8",
		category: "Plain T Shirt",
		productionLeadTime: "Production Lead Time: 30 Days",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar: V-Neck",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/plain t shirt/24.png"),
			require("../Images/Products/plain t shirt/24.png"),
			require("../Images/Products/plain t shirt/24.png"),
			require("../Images/Products/plain t shirt/24.png"),
			require("../Images/Products/plain t shirt/24.png"),
			require("../Images/Products/plain t shirt/24.png"),
		],
		button: "Enquire",
	},
	{
		id: 25,
		name: "Men Plain V-Neck T-Shirt 25 Blue",
		price: "Price : $3.8",
		category: "Plain T Shirt",
		productionLeadTime: "Production Lead Time: 30 Days",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar: V-Neck",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/plain t shirt/25.png"),
			require("../Images/Products/plain t shirt/25.png"),
			require("../Images/Products/plain t shirt/25.png"),
			require("../Images/Products/plain t shirt/25.png"),
			require("../Images/Products/plain t shirt/25.png"),
			require("../Images/Products/plain t shirt/25.png"),
		],
		button: "Enquire",
	},
	{
		id: 26,
		name: "Men Plain V-Neck T-Shirt 26 Light Blue ",
		price: "Price : $3.8",
		category: "Plain T Shirt",
		productionLeadTime: "Production Lead Time: 30 Days",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar: V-Neck",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/plain t shirt/26.png"),
			require("../Images/Products/plain t shirt/26.png"),
			require("../Images/Products/plain t shirt/26.png"),
			require("../Images/Products/plain t shirt/26.png"),
			require("../Images/Products/plain t shirt/26.png"),
			require("../Images/Products/plain t shirt/26.png"),
		],
		button: "Enquire",
	},
	{
		id: 27,
		name: "Men Plain O-Neck T-Shirt 27 off White ",
		price: "Price : $3.8",
		category: "Plain T Shirt",
		productionLeadTime: "Production Lead Time: 30 Days",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar: O-Neck",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/plain t shirt/27.png"),
			require("../Images/Products/plain t shirt/27.png"),
			require("../Images/Products/plain t shirt/27.png"),
			require("../Images/Products/plain t shirt/27.png"),
			require("../Images/Products/plain t shirt/27.png"),
			require("../Images/Products/plain t shirt/27.png"),
		],
		button: "Enquire",
	},
	{
		id: 28,
		name: "Men Plain O-Neck T-Shirt 28 Sage",
		price: "Price : $3.8",
		category: "Plain T Shirt",
		productionLeadTime: "Production Lead Time: 30 Days",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar: O-Neck",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/plain t shirt/28.png"),
			require("../Images/Products/plain t shirt/28.png"),
			require("../Images/Products/plain t shirt/28.png"),
			require("../Images/Products/plain t shirt/28.png"),
			require("../Images/Products/plain t shirt/28.png"),
			require("../Images/Products/plain t shirt/28.png"),
		],
		button: "Enquire",
	},
	{
		id: 29,
		name: "Men Plain O-Neck T-Shirt 29 Dark Gray",
		price: "Price : $3.8",
		category: "Plain T Shirt",
		productionLeadTime: "Production Lead Time: 30 Days",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar: O-Neck",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/plain t shirt/29.png"),
			require("../Images/Products/plain t shirt/29.png"),
			require("../Images/Products/plain t shirt/29.png"),
			require("../Images/Products/plain t shirt/29.png"),
			require("../Images/Products/plain t shirt/29.png"),
			require("../Images/Products/plain t shirt/29.png"),
		],
		button: "Enquire",
	},
	{
		id: 30,
		name: "Men Plain V-Neck T-Shirt 30 Dark Blue",
		price: "Price : $3.8",
		category: "Plain T Shirt",
		productionLeadTime: "Production Lead Time: 30 Days",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar: V-Neck",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/plain t shirt/30.png"),
			require("../Images/Products/plain t shirt/30.png"),
			require("../Images/Products/plain t shirt/30.png"),
			require("../Images/Products/plain t shirt/30.png"),
			require("../Images/Products/plain t shirt/30.png"),
			require("../Images/Products/plain t shirt/30.png"),
		],
		button: "Enquire",
	},
	{
		id: 31,
		name: "Men Polo T Shirt 1 Pink Bright",
		price: "Price : $5.7",
		category: "Polo T Shirt",
		productionLeadTime: "Production Lead Time: 40 Days",
		Sizes: "Available Size: S, M, L, XL, XXL XXXL",
		Collar: "Collar: Polo",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/polo t shirt/1.png"),
			require("../Images/Products/polo t shirt/1.png"),
			require("../Images/Products/polo t shirt/1.png"),
			require("../Images/Products/polo t shirt/1.png"),
			require("../Images/Products/polo t shirt/1.png"),
			require("../Images/Products/polo t shirt/1.png"),
		],
		button: "Enquire",
	},
	{
		id: 32,
		name: "Men Polo T Shirt 2 Light Brown",
		price: "Price : $5.7",
		category: "Polo T Shirt",
		productionLeadTime: "Production Lead Time: 40 Days",
		Sizes: "Available Size: S, M, L, XL, XXL XXXL",
		Collar: "Collar: Polo",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/polo t shirt/2.png"),
			require("../Images/Products/polo t shirt/2.png"),
			require("../Images/Products/polo t shirt/2.png"),
			require("../Images/Products/polo t shirt/2.png"),
			require("../Images/Products/polo t shirt/2.png"),
			require("../Images/Products/polo t shirt/2.png"),
		],
		button: "Enquire",
	},
	{
		id: 33,
		name: "Men Polo T Shirt 3 Brown",
		price: "Price : $5.7",
		category: "Polo T Shirt",
		productionLeadTime: "Production Lead Time: 40 Days",
		Sizes: "Available Size: S, M, L, XL, XXL XXXL",
		Collar: "Collar: Polo",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/polo t shirt/3.png"),
			require("../Images/Products/polo t shirt/3.png"),
			require("../Images/Products/polo t shirt/3.png"),
			require("../Images/Products/polo t shirt/3.png"),
			require("../Images/Products/polo t shirt/3.png"),
			require("../Images/Products/polo t shirt/3.png"),
		],
		button: "Enquire",
	},
	{
		id: 34,
		name: "Men Polo T Shirt 4 Light Green",
		price: "Price : $5.7",
		category: "Polo T Shirt",
		productionLeadTime: "Production Lead Time: 40 Days",
		Sizes: "Available Size: S, M, L, XL, XXL XXXL",
		Collar: "Collar: Polo",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/polo t shirt/4.png"),
			require("../Images/Products/polo t shirt/4.png"),
			require("../Images/Products/polo t shirt/4.png"),
			require("../Images/Products/polo t shirt/4.png"),
			require("../Images/Products/polo t shirt/4.png"),
			require("../Images/Products/polo t shirt/4.png"),
		],
		button: "Enquire",
	},
	{
		id: 35,
		name: "Men Polo T Shirt 5  Bright Red",
		price: "Price : $5.7",
		category: "Polo T Shirt",
		productionLeadTime: "Production Lead Time: 40 Days",
		Sizes: "Available Size: S, M, L, XL, XXL XXXL",
		Collar: "Collar: Polo",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/polo t shirt/5.png"),
			require("../Images/Products/polo t shirt/5.png"),
			require("../Images/Products/polo t shirt/5.png"),
			require("../Images/Products/polo t shirt/5.png"),
			require("../Images/Products/polo t shirt/5.png"),
			require("../Images/Products/polo t shirt/5.png"),
		],
		button: "Enquire",
	},
	{
		id: 36,
		name: "Men Polo T Shirt 6 Red Dark",
		price: "Price : $5.7",
		category: "Polo T Shirt",
		productionLeadTime: "Production Lead Time: 40 Days",
		Sizes: "Available Size: S, M, L, XL, XXL XXXL",
		Collar: "Collar: Polo",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/polo t shirt/6.png"),
			require("../Images/Products/polo t shirt/6.png"),
			require("../Images/Products/polo t shirt/6.png"),
			require("../Images/Products/polo t shirt/6.png"),
			require("../Images/Products/polo t shirt/6.png"),
			require("../Images/Products/polo t shirt/6.png"),
		],
		button: "Enquire",
	},
	{
		id: 37,
		name: "Men Polo T Shirt 7 Light Sky Blue",
		price: "Price : $5.7",
		category: "Polo T Shirt",
		productionLeadTime: "Production Lead Time: 40 Days",
		Sizes: "Available Size: S, M, L, XL, XXL XXXL",
		Collar: "Collar: Polo",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/polo t shirt/7.png"),
			require("../Images/Products/polo t shirt/7.png"),
			require("../Images/Products/polo t shirt/7.png"),
			require("../Images/Products/polo t shirt/7.png"),
			require("../Images/Products/polo t shirt/7.png"),
			require("../Images/Products/polo t shirt/7.png"),
		],
		button: "Enquire",
	},
	{
		id: 38,
		name: "Men Polo T Shirt 8 Blue Mid",
		price: "Price : $5.7",
		category: "Polo T Shirt",
		productionLeadTime: "Production Lead Time: 40 Days",
		Sizes: "Available Size: S, M, L, XL, XXL XXXL",
		Collar: "Collar: Polo",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/polo t shirt/8.png"),
			require("../Images/Products/polo t shirt/8.png"),
			require("../Images/Products/polo t shirt/8.png"),
			require("../Images/Products/polo t shirt/8.png"),
			require("../Images/Products/polo t shirt/8.png"),
			require("../Images/Products/polo t shirt/8.png"),
		],
		button: "Enquire",
	},
	{
		id: 39,
		name: "Men Polo T Shirt 9 Navy Blue",
		price: "Price : $5.7",
		category: "Polo T Shirt",
		productionLeadTime: "Production Lead Time: 40 Days",
		Sizes: "Available Size: S, M, L, XL, XXL XXXL",
		Collar: "Collar: Polo",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/polo t shirt/9.png"),
			require("../Images/Products/polo t shirt/9.png"),
			require("../Images/Products/polo t shirt/9.png"),
			require("../Images/Products/polo t shirt/9.png"),
			require("../Images/Products/polo t shirt/9.png"),
			require("../Images/Products/polo t shirt/9.png"),
		],
		button: "Enquire",
	},
	{
		id: 40,
		name: "Polo T Shirt 10",
		price: "Price : $5.7",
		category: "Polo T Shirt",
		productionLeadTime: "Production Lead Time: 40 Days",
		Sizes: "Available Size: S, M, L, XL, XXL XXXL",
		Collar: "Collar: Polo",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/polo t shirt/10.png"),
			require("../Images/Products/polo t shirt/10.png"),
			require("../Images/Products/polo t shirt/10.png"),
			require("../Images/Products/polo t shirt/10.png"),
			require("../Images/Products/polo t shirt/10.png"),
			require("../Images/Products/polo t shirt/10.png"),
		],
		button: "Enquire",
	},
	{
		id: 41,
		name: "Men Polo T Shirt 11 Blue Teal",
		price: "Price : $5.7",
		category: "Polo T Shirt",
		productionLeadTime: "Production Lead Time: 40 Days",
		Sizes: "Available Size: S, M, L, XL, XXL XXXL",
		Collar: "Collar: Polo",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/polo t shirt/11.png"),
			require("../Images/Products/polo t shirt/11.png"),
			require("../Images/Products/polo t shirt/11.png"),
			require("../Images/Products/polo t shirt/11.png"),
			require("../Images/Products/polo t shirt/11.png"),
			require("../Images/Products/polo t shirt/11.png"),
		],
		button: "Enquire",
	},
	{
		id: 42,
		name: "Men Polo T Shirt 12 Orange",
		price: "Price : $5.7",
		category: "Polo T Shirt",
		productionLeadTime: "Production Lead Time: 40 Days",
		Sizes: "Available Size: S, M, L, XL, XXL XXXL",
		Collar: "Collar: Polo",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/polo t shirt/12.png"),
			require("../Images/Products/polo t shirt/12.png"),
			require("../Images/Products/polo t shirt/12.png"),
			require("../Images/Products/polo t shirt/12.png"),
			require("../Images/Products/polo t shirt/12.png"),
			require("../Images/Products/polo t shirt/12.png"),
		],
		button: "Enquire",
	},
	{
		id: 43,
		name: "Men Polo T Shirt 13 Light Brown",
		price: "Price : $5.7",
		category: "Polo T Shirt",
		productionLeadTime: "Production Lead Time: 40 Days",
		Sizes: "Available Size: S, M, L, XL, XXL XXXL",
		Collar: "Collar: Polo",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/polo t shirt/13.png"),
			require("../Images/Products/polo t shirt/13.png"),
			require("../Images/Products/polo t shirt/13.png"),
			require("../Images/Products/polo t shirt/13.png"),
			require("../Images/Products/polo t shirt/13.png"),
			require("../Images/Products/polo t shirt/13.png"),
		],
		button: "Enquire",
	},
	{
		id: 44,
		name: "Men Polo T Shirt 14 Light Green",
		price: "Price : $5.7",
		category: "Polo T Shirt",
		productionLeadTime: "Production Lead Time: 40 Days",
		Sizes: "Available Size: S, M, L, XL, XXL XXXL",
		Collar: "Collar: Polo",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/polo t shirt/14.png"),
			require("../Images/Products/polo t shirt/14.png"),
			require("../Images/Products/polo t shirt/14.png"),
			require("../Images/Products/polo t shirt/14.png"),
			require("../Images/Products/polo t shirt/14.png"),
			require("../Images/Products/polo t shirt/14.png"),
		],
		button: "Enquire",
	},
	{
		id: 45,
		name: "Men Polo T Shirt 15 Green",
		price: "Price : $5.7",
		category: "Polo T Shirt",
		productionLeadTime: "Production Lead Time: 40 Days",
		Sizes: "Available Size: S, M, L, XL, XXL XXXL",
		Collar: "Collar: Polo",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/polo t shirt/15.png"),
			require("../Images/Products/polo t shirt/15.png"),
			require("../Images/Products/polo t shirt/15.png"),
			require("../Images/Products/polo t shirt/15.png"),
			require("../Images/Products/polo t shirt/15.png"),
			require("../Images/Products/polo t shirt/15.png"),
		],
		button: "Enquire",
	},
	{
		id: 46,
		name: "Men Polo T Shirt 16 Light Gray",
		price: "Price : $5.7",
		category: "Polo T Shirt",
		productionLeadTime: "Production Lead Time: 40 Days",
		Sizes: "Available Size: S, M, L, XL, XXL XXXL",
		Collar: "Collar: Polo",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/polo t shirt/16.png"),
			require("../Images/Products/polo t shirt/16.png"),
			require("../Images/Products/polo t shirt/16.png"),
			require("../Images/Products/polo t shirt/16.png"),
			require("../Images/Products/polo t shirt/16.png"),
			require("../Images/Products/polo t shirt/16.png"),
		],
		button: "Enquire",
	},
	{
		id: 47,
		name: "Men Polo T Shirt 17 Blue",
		price: "Price : $5.7",
		category: "Polo T Shirt",
		productionLeadTime: "Production Lead Time: 40 Days",
		Sizes: "Available Size: S, M, L, XL, XXL XXXL",
		Collar: "Collar: Polo",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/polo t shirt/17.png"),
			require("../Images/Products/polo t shirt/17.png"),
			require("../Images/Products/polo t shirt/17.png"),
			require("../Images/Products/polo t shirt/17.png"),
			require("../Images/Products/polo t shirt/17.png"),
			require("../Images/Products/polo t shirt/17.png"),
		],
		button: "Enquire",
	},
	{
		id: 48,
		name: "Men Polo T Shirt 18 Neutral",
		price: "Price : $5.7",
		category: "Polo T Shirt",
		productionLeadTime: "Production Lead Time: 40 Days",
		Sizes: "Available Size: S, M, L, XL, XXL XXXL",
		Collar: "Collar: Polo",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/polo t shirt/18.png"),
			require("../Images/Products/polo t shirt/18.png"),
			require("../Images/Products/polo t shirt/18.png"),
			require("../Images/Products/polo t shirt/18.png"),
			require("../Images/Products/polo t shirt/18.png"),
			require("../Images/Products/polo t shirt/18.png"),
		],
		button: "Enquire",
	},
	{
		id: 49,
		name: "Men Polo T Shirt 19 Purple",
		price: "Price : $5.7",
		category: "Polo T Shirt",
		productionLeadTime: "Production Lead Time: 40 Days",
		Sizes: "Available Size: S, M, L, XL, XXL XXXL",
		Collar: "Collar: Polo",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/polo t shirt/19.png"),
			require("../Images/Products/polo t shirt/19.png"),
			require("../Images/Products/polo t shirt/19.png"),
			require("../Images/Products/polo t shirt/19.png"),
			require("../Images/Products/polo t shirt/19.png"),
			require("../Images/Products/polo t shirt/19.png"),
		],
		button: "Enquire",
	},
	{
		id: 50,
		name: "Men Polo T Shirt 20 Dark Pink",
		price: "Price : $5.7",
		category: "Polo T Shirt",
		productionLeadTime: "Production Lead Time: 40 Days",
		Sizes: "Available Size: S, M, L, XL, XXL XXXL",
		Collar: "Collar: Polo",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/polo t shirt/20.png"),
			require("../Images/Products/polo t shirt/20.png"),
			require("../Images/Products/polo t shirt/20.png"),
			require("../Images/Products/polo t shirt/20.png"),
			require("../Images/Products/polo t shirt/20.png"),
			require("../Images/Products/polo t shirt/20.png"),
		],
		button: "Enquire",
	},
	{
		id: 51,
		name: "Men Polo T Shirt 21 Dark Purple",
		price: "Price : $5.7",
		category: "Polo T Shirt",
		productionLeadTime: "Production Lead Time: 40 Days",
		Sizes: "Available Size: S, M, L, XL, XXL XXXL",
		Collar: "Collar: Polo",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/polo t shirt/21.png"),
			require("../Images/Products/polo t shirt/21.png"),
			require("../Images/Products/polo t shirt/21.png"),
			require("../Images/Products/polo t shirt/21.png"),
			require("../Images/Products/polo t shirt/21.png"),
			require("../Images/Products/polo t shirt/21.png"),
		],
		button: "Enquire",
	},
	{
		id: 52,
		name: "Men Polo T Shirt 22 Light Blue",
		price: "Price : $5.7",
		category: "Polo T Shirt",
		productionLeadTime: "Production Lead Time: 40 Days",
		Sizes: "Available Size: S, M, L, XL, XXL XXXL",
		Collar: "Collar: Polo",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/polo t shirt/22.png"),
			require("../Images/Products/polo t shirt/22.png"),
			require("../Images/Products/polo t shirt/22.png"),
			require("../Images/Products/polo t shirt/22.png"),
			require("../Images/Products/polo t shirt/22.png"),
			require("../Images/Products/polo t shirt/22.png"),
		],
		button: "Enquire",
	},
	{
		id: 53,
		name: "Men Polo T Shirt 23 Black",
		price: "Price : $5.7",
		category: "Polo T Shirt",
		productionLeadTime: "Production Lead Time: 40 Days",
		Sizes: "Available Size: S, M, L, XL, XXL XXXL",
		Collar: "Collar: Polo",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/polo t shirt/23.png"),
			require("../Images/Products/polo t shirt/23.png"),
			require("../Images/Products/polo t shirt/23.png"),
			require("../Images/Products/polo t shirt/23.png"),
			require("../Images/Products/polo t shirt/23.png"),
			require("../Images/Products/polo t shirt/23.png"),
		],
		button: "Enquire",
	},
	{
		id: 54,
		name: "Men Polo T Shirt 24 Blue Cobalt",
		price: "Price : $5.7",
		category: "Polo T Shirt",
		productionLeadTime: "Production Lead Time: 40 Days",
		Sizes: "Available Size: S, M, L, XL, XXL XXXL",
		Collar: "Collar: Polo",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/polo t shirt/24.png"),
			require("../Images/Products/polo t shirt/24.png"),
			require("../Images/Products/polo t shirt/24.png"),
			require("../Images/Products/polo t shirt/24.png"),
			require("../Images/Products/polo t shirt/24.png"),
			require("../Images/Products/polo t shirt/24.png"),
		],
		button: "Enquire",
	},
	{
		id: 55,
		name: "Men Polo T Shirt 25 Sky Blue",
		price: "Price : $5.7",
		category: "Polo T Shirt",
		productionLeadTime: "Production Lead Time: 40 Days",
		Sizes: "Available Size: S, M, L, XL, XXL XXXL",
		Collar: "Collar: Polo",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/polo t shirt/25.png"),
			require("../Images/Products/polo t shirt/25.png"),
			require("../Images/Products/polo t shirt/25.png"),
			require("../Images/Products/polo t shirt/25.png"),
			require("../Images/Products/polo t shirt/25.png"),
			require("../Images/Products/polo t shirt/25.png"),
		],
		button: "Enquire",
	},
	{
		id: 56,
		name: "Men Polo T Shirt 26 Yellow",
		price: "Price : $5.7",
		category: "Polo T Shirt",
		productionLeadTime: "Production Lead Time: 40 Days",
		Sizes: "Available Size: S, M, L, XL, XXL XXXL",
		Collar: "Collar: Polo",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/polo t shirt/26.png"),
			require("../Images/Products/polo t shirt/26.png"),
			require("../Images/Products/polo t shirt/26.png"),
			require("../Images/Products/polo t shirt/26.png"),
			require("../Images/Products/polo t shirt/26.png"),
			require("../Images/Products/polo t shirt/26.png"),
		],
		button: "Enquire",
	},
	{
		id: 57,
		name: "Men Polo T Shirt 27 Blue Teal ",
		price: "Price : $5.7",
		category: "Polo T Shirt",
		productionLeadTime: "Production Lead Time: 40 Days",
		Sizes: "Available Size: S, M, L, XL, XXL XXXL",
		Collar: "Collar: Polo",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/polo t shirt/27.png"),
			require("../Images/Products/polo t shirt/27.png"),
			require("../Images/Products/polo t shirt/27.png"),
			require("../Images/Products/polo t shirt/27.png"),
			require("../Images/Products/polo t shirt/27.png"),
			require("../Images/Products/polo t shirt/27.png"),
		],
		button: "Enquire",
	},
	{
		id: 58,
		name: "Men Polo T Shirt 28 Neutral",
		price: "Price : $5.7",
		category: "Polo T Shirt",
		productionLeadTime: "Production Lead Time: 40 Days",
		Sizes: "Available Size: S, M, L, XL, XXL XXXL",
		Collar: "Collar: Polo",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/polo t shirt/28.png"),
			require("../Images/Products/polo t shirt/28.png"),
			require("../Images/Products/polo t shirt/28.png"),
			require("../Images/Products/polo t shirt/28.png"),
			require("../Images/Products/polo t shirt/28.png"),
			require("../Images/Products/polo t shirt/28.png"),
		],
		button: "Enquire",
	},
	{
		id: 59,
		name: "Men Polo T Shirt 29 Stone",
		price: "Price : $5.7",
		category: "Polo T Shirt",
		productionLeadTime: "Production Lead Time: 40 Days",
		Sizes: "Available Size: S, M, L, XL, XXL XXXL",
		Collar: "Collar: Polo",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/polo t shirt/29.png"),
			require("../Images/Products/polo t shirt/29.png"),
			require("../Images/Products/polo t shirt/29.png"),
			require("../Images/Products/polo t shirt/29.png"),
			require("../Images/Products/polo t shirt/29.png"),
			require("../Images/Products/polo t shirt/29.png"),
		],
		button: "Enquire",
	},
	{
		id: 60,
		name: "Men Polo T Shirt 30 Dark Green",
		price: "Price : $5.7",
		category: "Polo T Shirt",
		productionLeadTime: "Production Lead Time: 40 Days",
		Sizes: "Available Size: S, M, L, XL, XXL XXXL",
		Collar: "Collar: Polo",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/polo t shirt/30.png"),
			require("../Images/Products/polo t shirt/30.png"),
			require("../Images/Products/polo t shirt/30.png"),
			require("../Images/Products/polo t shirt/30.png"),
			require("../Images/Products/polo t shirt/30.png"),
			require("../Images/Products/polo t shirt/30.png"),
		],
		button: "Enquire",
	},
	{
		id: 61,
		name: "Men Short 1 Dark Green",
		price: "Price : $39.99",
		category: "Shorts",
		productionLeadTime: "Production Lead Time: 25 Days",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Stay active and stylish with our Ultimate Performance Shorts, designed to deliver maximum comfort and durability for any activity. Whether you’re hitting the gym, running errands, or lounging at home, these shorts provide the perfect balance of function and fashion. Crafted from premium, moisture-wicking fabric, these shorts keep you cool and dry even during intense workouts. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			materia: "Soft, lightweight, and breathable fabric blend",
			fit: "Relaxed fit with an adjustable elastic waistband",
			durability: "Reinforced stitching for long-lasting wear",
			pockets: " Deep side pockets for secure storage of essentials like phone, keys, or wallet",
			stretchFabric: "4-way stretch fabric for maximum flexibility and comfort",
			versatileDesign: "Suitable for sports, workouts, or casual wear",
		},
		imageUrl: [
			require("../Images/Products/Shorts/1.png"),
			require("../Images/Products/Shorts/1.png"),
			require("../Images/Products/Shorts/1.png"),
			require("../Images/Products/Shorts/1.png"),
			require("../Images/Products/Shorts/1.png"),
			require("../Images/Products/Shorts/1.png"),
		],
		button: "Enquire",
	},
	{
		id: 62,
		name: "Men Short 2 Tan Brown",
		price: "Price : $39.99",
		category: "Shorts",
		productionLeadTime: "Production Lead Time: ABC",
		color: "Color: NA",
		Sizes: "Available Size: S, M, L, XL",
		minimunOrderQuantity: "Menimum Order Quantity: NA",
		fabricType: "Fabric Type: NA",
		Material: "Material: NA",
		Customised: "Customized? YES",
		description:
			"Stay active and stylish with our Ultimate Performance Shorts, designed to deliver maximum comfort and durability for any activity. Whether you’re hitting the gym, running errands, or lounging at home, these shorts provide the perfect balance of function and fashion. Crafted from premium, moisture-wicking fabric, these shorts keep you cool and dry even during intense workouts. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			materia: "Soft, lightweight, and breathable fabric blend",
			fit: "Relaxed fit with an adjustable elastic waistband",
			durability: "Reinforced stitching for long-lasting wear",
			pockets: " Deep side pockets for secure storage of essentials like phone, keys, or wallet",
			stretchFabric: "4-way stretch fabric for maximum flexibility and comfort",
			versatileDesign: "Suitable for sports, workouts, or casual wear",
		},
		imageUrl: [
			require("../Images/Products/Shorts/2.png"),
			require("../Images/Products/Shorts/2.png"),
			require("../Images/Products/Shorts/2.png"),
			require("../Images/Products/Shorts/2.png"),
			require("../Images/Products/Shorts/2.png"),
			require("../Images/Products/Shorts/2.png"),
		],
		button: "Enquire",
	},
	{
		id: 63,
		name: "Men Short 3 Black",
		price: "Price : $39.99",
		category: "Shorts",
		productionLeadTime: "Production Lead Time: ABC",
		color: "Color: NA",
		Sizes: "Available Size: S, M, L, XL",
		minimunOrderQuantity: "Menimum Order Quantity: NA",
		fabricType: "Fabric Type: NA",
		Material: "Material: NA",
		Customised: "Customized? YES",
		description:
			"Stay active and stylish with our Ultimate Performance Shorts, designed to deliver maximum comfort and durability for any activity. Whether you’re hitting the gym, running errands, or lounging at home, these shorts provide the perfect balance of function and fashion. Crafted from premium, moisture-wicking fabric, these shorts keep you cool and dry even during intense workouts. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			materia: "Soft, lightweight, and breathable fabric blend",
			fit: "Relaxed fit with an adjustable elastic waistband",
			durability: "Reinforced stitching for long-lasting wear",
			pockets: " Deep side pockets for secure storage of essentials like phone, keys, or wallet",
			stretchFabric: "4-way stretch fabric for maximum flexibility and comfort",
			versatileDesign: "Suitable for sports, workouts, or casual wear",
		},
		imageUrl: [
			require("../Images/Products/Shorts/3.png"),
			require("../Images/Products/Shorts/3.png"),
			require("../Images/Products/Shorts/3.png"),
			require("../Images/Products/Shorts/3.png"),
			require("../Images/Products/Shorts/3.png"),
			require("../Images/Products/Shorts/3.png"),
		],
		button: "Enquire",
	},
	{
		id: 64,
		name: "Men Short 4 White",
		price: "Price : $39.99",
		category: "Shorts",
		productionLeadTime: "Production Lead Time: ABC",
		color: "Color: NA",
		Sizes: "Available Size: S, M, L, XL",
		minimunOrderQuantity: "Menimum Order Quantity: NA",
		fabricType: "Fabric Type: NA",
		Material: "Material: NA",
		Customised: "Customized? YES",
		description:
			"Stay active and stylish with our Ultimate Performance Shorts, designed to deliver maximum comfort and durability for any activity. Whether you’re hitting the gym, running errands, or lounging at home, these shorts provide the perfect balance of function and fashion. Crafted from premium, moisture-wicking fabric, these shorts keep you cool and dry even during intense workouts. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			materia: "Soft, lightweight, and breathable fabric blend",
			fit: "Relaxed fit with an adjustable elastic waistband",
			durability: "Reinforced stitching for long-lasting wear",
			pockets: " Deep side pockets for secure storage of essentials like phone, keys, or wallet",
			stretchFabric: "4-way stretch fabric for maximum flexibility and comfort",
			versatileDesign: "Suitable for sports, workouts, or casual wear",
		},
		imageUrl: [
			require("../Images/Products/Shorts/4.png"),
			require("../Images/Products/Shorts/4.png"),
			require("../Images/Products/Shorts/4.png"),
			require("../Images/Products/Shorts/4.png"),
			require("../Images/Products/Shorts/4.png"),
			require("../Images/Products/Shorts/4.png"),
		],
		button: "Enquire",
	},
	{
		id: 65,
		name: "Men Short 5 Green",
		price: "Price : $39.99",
		category: "Shorts",
		productionLeadTime: "Production Lead Time: ABC",
		color: "Color: NA",
		Sizes: "Available Size: S, M, L, XL",
		minimunOrderQuantity: "Menimum Order Quantity: NA",
		fabricType: "Fabric Type: NA",
		Material: "Material: NA",
		Customised: "Customized? YES",
		description:
			"Stay active and stylish with our Ultimate Performance Shorts, designed to deliver maximum comfort and durability for any activity. Whether you’re hitting the gym, running errands, or lounging at home, these shorts provide the perfect balance of function and fashion. Crafted from premium, moisture-wicking fabric, these shorts keep you cool and dry even during intense workouts. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			materia: "Soft, lightweight, and breathable fabric blend",
			fit: "Relaxed fit with an adjustable elastic waistband",
			durability: "Reinforced stitching for long-lasting wear",
			pockets: " Deep side pockets for secure storage of essentials like phone, keys, or wallet",
			stretchFabric: "4-way stretch fabric for maximum flexibility and comfort",
			versatileDesign: "Suitable for sports, workouts, or casual wear",
		},
		imageUrl: [
			require("../Images/Products/Shorts/5.png"),
			require("../Images/Products/Shorts/5.png"),
			require("../Images/Products/Shorts/5.png"),
			require("../Images/Products/Shorts/5.png"),
			require("../Images/Products/Shorts/5.png"),
			require("../Images/Products/Shorts/5.png"),
		],
		button: "Enquire",
	},
	{
		id: 66,
		name: "Men Short 6 Sky Blue",
		price: "Price : $39.99",
		category: "Shorts",
		productionLeadTime: "Production Lead Time: ABC",
		color: "Color: NA",
		Sizes: "Available Size: S, M, L, XL",
		minimunOrderQuantity: "Menimum Order Quantity: NA",
		fabricType: "Fabric Type: NA",
		Material: "Material: NA",
		Customised: "Customized? YES",
		description:
			"Stay active and stylish with our Ultimate Performance Shorts, designed to deliver maximum comfort and durability for any activity. Whether you’re hitting the gym, running errands, or lounging at home, these shorts provide the perfect balance of function and fashion. Crafted from premium, moisture-wicking fabric, these shorts keep you cool and dry even during intense workouts. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			materia: "Soft, lightweight, and breathable fabric blend",
			fit: "Relaxed fit with an adjustable elastic waistband",
			durability: "Reinforced stitching for long-lasting wear",
			pockets: " Deep side pockets for secure storage of essentials like phone, keys, or wallet",
			stretchFabric: "4-way stretch fabric for maximum flexibility and comfort",
			versatileDesign: "Suitable for sports, workouts, or casual wear",
		},
		imageUrl: [
			require("../Images/Products/Shorts/6.png"),
			require("../Images/Products/Shorts/6.png"),
			require("../Images/Products/Shorts/6.png"),
			require("../Images/Products/Shorts/6.png"),
			require("../Images/Products/Shorts/6.png"),
			require("../Images/Products/Shorts/6.png"),
		],
		button: "Enquire",
	},
	{
		id: 67,
		name: "Men Short 7 Light Orange",
		price: "Price : $39.99",
		category: "Shorts",
		productionLeadTime: "Production Lead Time: ABC",
		color: "Color: NA",
		Sizes: "Available Size: S, M, L, XL",
		minimunOrderQuantity: "Menimum Order Quantity: NA",
		fabricType: "Fabric Type: NA",
		Material: "Material: NA",
		Customised: "Customized? YES",
		description:
			"Stay active and stylish with our Ultimate Performance Shorts, designed to deliver maximum comfort and durability for any activity. Whether you’re hitting the gym, running errands, or lounging at home, these shorts provide the perfect balance of function and fashion. Crafted from premium, moisture-wicking fabric, these shorts keep you cool and dry even during intense workouts. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			materia: "Soft, lightweight, and breathable fabric blend",
			fit: "Relaxed fit with an adjustable elastic waistband",
			durability: "Reinforced stitching for long-lasting wear",
			pockets: " Deep side pockets for secure storage of essentials like phone, keys, or wallet",
			stretchFabric: "4-way stretch fabric for maximum flexibility and comfort",
			versatileDesign: "Suitable for sports, workouts, or casual wear",
		},
		imageUrl: [
			require("../Images/Products/Shorts/7.png"),
			require("../Images/Products/Shorts/7.png"),
			require("../Images/Products/Shorts/7.png"),
			require("../Images/Products/Shorts/7.png"),
			require("../Images/Products/Shorts/7.png"),
			require("../Images/Products/Shorts/7.png"),
		],
		button: "Enquire",
	},
	{
		id: 68,
		name: "Men Short 8 Coffee Brown",
		price: "Price : $39.99",
		category: "Shorts",
		productionLeadTime: "Production Lead Time: ABC",
		color: "Color: NA",
		Sizes: "Available Size: S, M, L, XL",
		minimunOrderQuantity: "Menimum Order Quantity: NA",
		fabricType: "Fabric Type: NA",
		Material: "Material: NA",
		Customised: "Customized? YES",
		description:
			"Stay active and stylish with our Ultimate Performance Shorts, designed to deliver maximum comfort and durability for any activity. Whether you’re hitting the gym, running errands, or lounging at home, these shorts provide the perfect balance of function and fashion. Crafted from premium, moisture-wicking fabric, these shorts keep you cool and dry even during intense workouts. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			materia: "Soft, lightweight, and breathable fabric blend",
			fit: "Relaxed fit with an adjustable elastic waistband",
			durability: "Reinforced stitching for long-lasting wear",
			pockets: " Deep side pockets for secure storage of essentials like phone, keys, or wallet",
			stretchFabric: "4-way stretch fabric for maximum flexibility and comfort",
			versatileDesign: "Suitable for sports, workouts, or casual wear",
		},
		imageUrl: [
			require("../Images/Products/Shorts/8.png"),
			require("../Images/Products/Shorts/8.png"),
			require("../Images/Products/Shorts/8.png"),
			require("../Images/Products/Shorts/8.png"),
			require("../Images/Products/Shorts/8.png"),
			require("../Images/Products/Shorts/8.png"),
		],
		button: "Enquire",
	},
	{
		id: 69,
		name: "Men Short 9 Light Orange",
		price: "Price : $39.99",
		category: "Shorts",
		productionLeadTime: "Production Lead Time: ABC",
		color: "Color: NA",
		Sizes: "Available Size: S, M, L, XL",
		minimunOrderQuantity: "Menimum Order Quantity: NA",
		fabricType: "Fabric Type: NA",
		Material: "Material: NA",
		Customised: "Customized? YES",
		description:
			"Stay active and stylish with our Ultimate Performance Shorts, designed to deliver maximum comfort and durability for any activity. Whether you’re hitting the gym, running errands, or lounging at home, these shorts provide the perfect balance of function and fashion. Crafted from premium, moisture-wicking fabric, these shorts keep you cool and dry even during intense workouts. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			materia: "Soft, lightweight, and breathable fabric blend",
			fit: "Relaxed fit with an adjustable elastic waistband",
			durability: "Reinforced stitching for long-lasting wear",
			pockets: " Deep side pockets for secure storage of essentials like phone, keys, or wallet",
			stretchFabric: "4-way stretch fabric for maximum flexibility and comfort",
			versatileDesign: "Suitable for sports, workouts, or casual wear",
		},
		imageUrl: [
			require("../Images/Products/Shorts/9.png"),
			require("../Images/Products/Shorts/9.png"),
			require("../Images/Products/Shorts/9.png"),
			require("../Images/Products/Shorts/9.png"),
			require("../Images/Products/Shorts/9.png"),
			require("../Images/Products/Shorts/9.png"),
		],
		button: "Enquire",
	},
	{
		id: 70,
		name: "Men Short 10 Light Gray",
		price: "Price : $39.99",
		category: "Shorts",
		productionLeadTime: "Production Lead Time: ABC",
		color: "Color: NA",
		Sizes: "Available Size: S, M, L, XL",
		minimunOrderQuantity: "Menimum Order Quantity: NA",
		fabricType: "Fabric Type: NA",
		Material: "Material: NA",
		Customised: "Customized? YES",
		description:
			"Stay active and stylish with our Ultimate Performance Shorts, designed to deliver maximum comfort and durability for any activity. Whether you’re hitting the gym, running errands, or lounging at home, these shorts provide the perfect balance of function and fashion. Crafted from premium, moisture-wicking fabric, these shorts keep you cool and dry even during intense workouts. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			materia: "Soft, lightweight, and breathable fabric blend",
			fit: "Relaxed fit with an adjustable elastic waistband",
			durability: "Reinforced stitching for long-lasting wear",
			pockets: " Deep side pockets for secure storage of essentials like phone, keys, or wallet",
			stretchFabric: "4-way stretch fabric for maximum flexibility and comfort",
			versatileDesign: "Suitable for sports, workouts, or casual wear",
		},
		imageUrl: [
			require("../Images/Products/Shorts/10.png"),
			require("../Images/Products/Shorts/10.png"),
			require("../Images/Products/Shorts/10.png"),
			require("../Images/Products/Shorts/10.png"),
			require("../Images/Products/Shorts/10.png"),
			require("../Images/Products/Shorts/10.png"),
		],
		button: "Enquire",
	},
	{
		id: 71,
		name: "Men Short 11 Orange",
		price: "Price : $39.99",
		category: "Shorts",
		productionLeadTime: "Production Lead Time: ABC",
		color: "Color: NA",
		Sizes: "Available Size: S, M, L, XL",
		minimunOrderQuantity: "Menimum Order Quantity: NA",
		fabricType: "Fabric Type: NA",
		Material: "Material: NA",
		Customised: "Customized? YES",
		description:
			"Stay active and stylish with our Ultimate Performance Shorts, designed to deliver maximum comfort and durability for any activity. Whether you’re hitting the gym, running errands, or lounging at home, these shorts provide the perfect balance of function and fashion. Crafted from premium, moisture-wicking fabric, these shorts keep you cool and dry even during intense workouts. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			materia: "Soft, lightweight, and breathable fabric blend",
			fit: "Relaxed fit with an adjustable elastic waistband",
			durability: "Reinforced stitching for long-lasting wear",
			pockets: " Deep side pockets for secure storage of essentials like phone, keys, or wallet",
			stretchFabric: "4-way stretch fabric for maximum flexibility and comfort",
			versatileDesign: "Suitable for sports, workouts, or casual wear",
		},
		imageUrl: [
			require("../Images/Products/Shorts/11.png"),
			require("../Images/Products/Shorts/11.png"),
			require("../Images/Products/Shorts/11.png"),
			require("../Images/Products/Shorts/11.png"),
			require("../Images/Products/Shorts/11.png"),
			require("../Images/Products/Shorts/11.png"),
		],
		button: "Enquire",
	},
	{
		id: 72,
		name: "Men Short 12 Black",
		price: "Price : $39.99",
		category: "Shorts",
		productionLeadTime: "Production Lead Time: ABC",
		color: "Color: NA",
		Sizes: "Available Size: S, M, L, XL",
		minimunOrderQuantity: "Menimum Order Quantity: NA",
		fabricType: "Fabric Type: NA",
		Material: "Material: NA",
		Customised: "Customized? YES",
		description:
			"Stay active and stylish with our Ultimate Performance Shorts, designed to deliver maximum comfort and durability for any activity. Whether you’re hitting the gym, running errands, or lounging at home, these shorts provide the perfect balance of function and fashion. Crafted from premium, moisture-wicking fabric, these shorts keep you cool and dry even during intense workouts. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			materia: "Soft, lightweight, and breathable fabric blend",
			fit: "Relaxed fit with an adjustable elastic waistband",
			durability: "Reinforced stitching for long-lasting wear",
			pockets: " Deep side pockets for secure storage of essentials like phone, keys, or wallet",
			stretchFabric: "4-way stretch fabric for maximum flexibility and comfort",
			versatileDesign: "Suitable for sports, workouts, or casual wear",
		},
		imageUrl: [
			require("../Images/Products/Shorts/12.png"),
			require("../Images/Products/Shorts/12.png"),
			require("../Images/Products/Shorts/12.png"),
			require("../Images/Products/Shorts/12.png"),
			require("../Images/Products/Shorts/12.png"),
			require("../Images/Products/Shorts/12.png"),
		],
		button: "Enquire",
	},
	{
		id: 73,
		name: "Men Short 13 Gray",
		price: "Price : $39.99",
		category: "Shorts",
		productionLeadTime: "Production Lead Time: ABC",
		color: "Color: NA",
		Sizes: "Available Size: S, M, L, XL",
		minimunOrderQuantity: "Menimum Order Quantity: NA",
		fabricType: "Fabric Type: NA",
		Material: "Material: NA",
		Customised: "Customized? YES",
		description:
			"Stay active and stylish with our Ultimate Performance Shorts, designed to deliver maximum comfort and durability for any activity. Whether you’re hitting the gym, running errands, or lounging at home, these shorts provide the perfect balance of function and fashion. Crafted from premium, moisture-wicking fabric, these shorts keep you cool and dry even during intense workouts. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			materia: "Soft, lightweight, and breathable fabric blend",
			fit: "Relaxed fit with an adjustable elastic waistband",
			durability: "Reinforced stitching for long-lasting wear",
			pockets: " Deep side pockets for secure storage of essentials like phone, keys, or wallet",
			stretchFabric: "4-way stretch fabric for maximum flexibility and comfort",
			versatileDesign: "Suitable for sports, workouts, or casual wear",
		},
		imageUrl: [
			require("../Images/Products/Shorts/13.png"),
			require("../Images/Products/Shorts/13.png"),
			require("../Images/Products/Shorts/13.png"),
			require("../Images/Products/Shorts/13.png"),
			require("../Images/Products/Shorts/13.png"),
			require("../Images/Products/Shorts/13.png"),
		],
		button: "Enquire",
	},
	{
		id: 74,
		name: "Men Short 14 Red",
		price: "Price : $39.99",
		category: "Shorts",
		productionLeadTime: "Production Lead Time: ABC",
		color: "Color: NA",
		Sizes: "Available Size: S, M, L, XL",
		minimunOrderQuantity: "Menimum Order Quantity: NA",
		fabricType: "Fabric Type: NA",
		Material: "Material: NA",
		Customised: "Customized? YES",
		description:
			"Stay active and stylish with our Ultimate Performance Shorts, designed to deliver maximum comfort and durability for any activity. Whether you’re hitting the gym, running errands, or lounging at home, these shorts provide the perfect balance of function and fashion. Crafted from premium, moisture-wicking fabric, these shorts keep you cool and dry even during intense workouts. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			materia: "Soft, lightweight, and breathable fabric blend",
			fit: "Relaxed fit with an adjustable elastic waistband",
			durability: "Reinforced stitching for long-lasting wear",
			pockets: " Deep side pockets for secure storage of essentials like phone, keys, or wallet",
			stretchFabric: "4-way stretch fabric for maximum flexibility and comfort",
			versatileDesign: "Suitable for sports, workouts, or casual wear",
		},
		imageUrl: [
			require("../Images/Products/Shorts/14.png"),
			require("../Images/Products/Shorts/14.png"),
			require("../Images/Products/Shorts/14.png"),
			require("../Images/Products/Shorts/14.png"),
			require("../Images/Products/Shorts/14.png"),
			require("../Images/Products/Shorts/14.png"),
		],
		button: "Enquire",
	},
	{
		id: 75,
		name: "Men Short 15 Black",
		price: "Price : $39.99",
		category: "Shorts",
		productionLeadTime: "Production Lead Time: ABC",
		color: "Color: NA",
		Sizes: "Available Size: S, M, L, XL",
		minimunOrderQuantity: "Menimum Order Quantity: NA",
		fabricType: "Fabric Type: NA",
		Material: "Material: NA",
		Customised: "Customized? YES",
		description:
			"Stay active and stylish with our Ultimate Performance Shorts, designed to deliver maximum comfort and durability for any activity. Whether you’re hitting the gym, running errands, or lounging at home, these shorts provide the perfect balance of function and fashion. Crafted from premium, moisture-wicking fabric, these shorts keep you cool and dry even during intense workouts. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			materia: "Soft, lightweight, and breathable fabric blend",
			fit: "Relaxed fit with an adjustable elastic waistband",
			durability: "Reinforced stitching for long-lasting wear",
			pockets: " Deep side pockets for secure storage of essentials like phone, keys, or wallet",
			stretchFabric: "4-way stretch fabric for maximum flexibility and comfort",
			versatileDesign: "Suitable for sports, workouts, or casual wear",
		},
		imageUrl: [
			require("../Images/Products/Shorts/15.png"),
			require("../Images/Products/Shorts/15.png"),
			require("../Images/Products/Shorts/15.png"),
			require("../Images/Products/Shorts/15.png"),
			require("../Images/Products/Shorts/15.png"),
			require("../Images/Products/Shorts/15.png"),
		],
		button: "Enquire",
	},
	{
		id: 76,
		name: "Men Cotton Printed T-Shirt 1",
		price: "Price : $5.8",
		category: "Printed T Shirt",
		productionLeadTime: "Production Lead Time: 35 Days",
		color: "Color: NA",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar: O-Neck",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Technics: "Technics: Printed",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/printed t shirt/1.png"),
			require("../Images/Products/printed t shirt/1.png"),
			require("../Images/Products/printed t shirt/1.png"),
			require("../Images/Products/printed t shirt/1.png"),
			require("../Images/Products/printed t shirt/1.png"),
			require("../Images/Products/printed t shirt/1.png"),
		],
		button: "Enquire",
	},
	{
		id: 77,
		name: "Men Cotton Printed T-Shirt 2",
		price: "Price : $5.8",
		category: "Printed T Shirt",
		productionLeadTime: "Production Lead Time: 35 Days",
		color: "Color: NA",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar: O-Neck",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Technics: "Technics: Printed",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/printed t shirt/2.png"),
			require("../Images/Products/printed t shirt/2.png"),
			require("../Images/Products/printed t shirt/2.png"),
			require("../Images/Products/printed t shirt/2.png"),
			require("../Images/Products/printed t shirt/2.png"),
			require("../Images/Products/printed t shirt/2.png"),
		],
		button: "Enquire",
	},
	{
		id: 78,
		name: "Men Cotton Printed T-Shirt 3",
		price: "Price : $5.5",
		category: "Printed T Shirt",
		productionLeadTime: "Production Lead Time: 35 Days",
		color: "Color: NA",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar: O-Neck",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Technics: "Technics: Printed",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/printed t shirt/3.png"),
			require("../Images/Products/printed t shirt/3.png"),
			require("../Images/Products/printed t shirt/3.png"),
			require("../Images/Products/printed t shirt/3.png"),
			require("../Images/Products/printed t shirt/3.png"),
			require("../Images/Products/printed t shirt/3.png"),
		],
		button: "Enquire",
	},
	{
		id: 79,
		name: "Men Cotton Printed T-Shirt 4",
		price: "Price : $5.8",
		category: "Printed T Shirt",
		productionLeadTime: "Production Lead Time: 35 Days",
		color: "Color: NA",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar: O-Neck",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Technics: "Technics: Printed",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/printed t shirt/4.png"),
			require("../Images/Products/printed t shirt/4.png"),
			require("../Images/Products/printed t shirt/4.png"),
			require("../Images/Products/printed t shirt/4.png"),
			require("../Images/Products/printed t shirt/4.png"),
			require("../Images/Products/printed t shirt/4.png"),
		],
		button: "Enquire",
	},
	{
		id: 80,
		name: "Men Cotton Printed T-Shirt 5",
		price: "Price : $5.8",
		category: "Printed T Shirt",
		productionLeadTime: "Production Lead Time: 35 Days",
		color: "Color: NA",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar: O-Neck",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Technics: "Technics: Printed",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/printed t shirt/5.png"),
			require("../Images/Products/printed t shirt/5.png"),
			require("../Images/Products/printed t shirt/5.png"),
			require("../Images/Products/printed t shirt/5.png"),
			require("../Images/Products/printed t shirt/5.png"),
			require("../Images/Products/printed t shirt/5.png"),
		],
		button: "Enquire",
	},
	{
		id: 81,
		name: "Men Cotton Printed T-Shirt 6",
		price: "Price : $5.5",
		category: "Printed T Shirt",
		productionLeadTime: "Production Lead Time: 35 Days",
		color: "Color: NA",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar: O-Neck",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Technics: "Technics: Printed",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/printed t shirt/6.png"),
			require("../Images/Products/printed t shirt/6.png"),
			require("../Images/Products/printed t shirt/6.png"),
			require("../Images/Products/printed t shirt/6.png"),
			require("../Images/Products/printed t shirt/6.png"),
			require("../Images/Products/printed t shirt/6.png"),
		],
		button: "Enquire",
	},
	{
		id: 82,
		name: "Men Cotton Printed T-Shirt 7",
		price: "Price : $5.8",
		category: "Printed T Shirt",
		productionLeadTime: "Production Lead Time: 35 Days",
		color: "Color: NA",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar: O-Neck",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Technics: "Technics: Printed",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/printed t shirt/7.png"),
			require("../Images/Products/printed t shirt/7.png"),
			require("../Images/Products/printed t shirt/7.png"),
			require("../Images/Products/printed t shirt/7.png"),
			require("../Images/Products/printed t shirt/7.png"),
			require("../Images/Products/printed t shirt/7.png"),
		],
		button: "Enquire",
	},
	{
		id: 83,
		name: "Men Cotton Printed T-Shirt 8",
		price: "Price : $5.5",
		category: "Printed T Shirt",
		productionLeadTime: "Production Lead Time: 35 Days",
		color: "Color: NA",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar: O-Neck",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Technics: "Technics: Printed",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/printed t shirt/8.png"),
			require("../Images/Products/printed t shirt/8.png"),
			require("../Images/Products/printed t shirt/8.png"),
			require("../Images/Products/printed t shirt/8.png"),
			require("../Images/Products/printed t shirt/8.png"),
			require("../Images/Products/printed t shirt/8.png"),
		],
		button: "Enquire",
	},
	{
		id: 84,
		name: "Men Cotton Printed T-Shirt 9",
		price: "Price : $5.5",
		category: "Printed T Shirt",
		productionLeadTime: "Production Lead Time: 35 Days",
		color: "Color: NA",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar: O-Neck",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Technics: "Technics: Printed",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/printed t shirt/9.png"),
			require("../Images/Products/printed t shirt/9.png"),
			require("../Images/Products/printed t shirt/9.png"),
			require("../Images/Products/printed t shirt/9.png"),
			require("../Images/Products/printed t shirt/9.png"),
			require("../Images/Products/printed t shirt/9.png"),
		],
		button: "Enquire",
	},
	{
		id: 85,
		name: "Men Cotton Printed T-Shirt 10",
		price: "Price : $5.8",
		category: "Printed T Shirt",
		productionLeadTime: "Production Lead Time: 35 Days",
		color: "Color: NA",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar: O-Neck",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Technics: "Technics: Printed",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/printed t shirt/10.png"),
			require("../Images/Products/printed t shirt/10.png"),
			require("../Images/Products/printed t shirt/10.png"),
			require("../Images/Products/printed t shirt/10.png"),
			require("../Images/Products/printed t shirt/10.png"),
			require("../Images/Products/printed t shirt/10.png"),
		],
		button: "Enquire",
	},
	{
		id: 86,
		name: "Men Cotton Printed T-Shirt 11",
		price: "Price : $5.8",
		category: "Printed T Shirt",
		productionLeadTime: "Production Lead Time: 35 Days",
		color: "Color: NA",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar: O-Neck",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Technics: "Technics: Printed",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/printed t shirt/11.png"),
			require("../Images/Products/printed t shirt/11.png"),
			require("../Images/Products/printed t shirt/11.png"),
			require("../Images/Products/printed t shirt/11.png"),
			require("../Images/Products/printed t shirt/11.png"),
			require("../Images/Products/printed t shirt/11.png"),
		],
		button: "Enquire",
	},
	{
		id: 87,
		name: "Men Cotton Printed T-Shirt 12",
		price: "Price : $5.8",
		category: "Printed T Shirt",
		productionLeadTime: "Production Lead Time: 35 Days",
		color: "Color: NA",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar: O-Neck",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Technics: "Technics: Printed",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/printed t shirt/12.png"),
			require("../Images/Products/printed t shirt/12.png"),
			require("../Images/Products/printed t shirt/12.png"),
			require("../Images/Products/printed t shirt/12.png"),
			require("../Images/Products/printed t shirt/12.png"),
			require("../Images/Products/printed t shirt/12.png"),
		],
		button: "Enquire",
	},
	{
		id: 88,
		name: "Men Cotton Printed T-Shirt 13",
		price: "Price : $5.5",
		category: "Printed T Shirt",
		productionLeadTime: "Production Lead Time: 35 Days",
		color: "Color: NA",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar: O-Neck",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Technics: "Technics: Printed",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/printed t shirt/13.png"),
			require("../Images/Products/printed t shirt/13.png"),
			require("../Images/Products/printed t shirt/13.png"),
			require("../Images/Products/printed t shirt/13.png"),
			require("../Images/Products/printed t shirt/13.png"),
			require("../Images/Products/printed t shirt/13.png"),
		],
		button: "Enquire",
	},
	{
		id: 89,
		name: "Men Cotton Printed T-Shirt 14",
		price: "Price : $5.8",
		category: "Printed T Shirt",
		productionLeadTime: "Production Lead Time: 35 Days",
		color: "Color: NA",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar: O-Neck",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Technics: "Technics: Printed",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/printed t shirt/14.png"),
			require("../Images/Products/printed t shirt/14.png"),
			require("../Images/Products/printed t shirt/14.png"),
			require("../Images/Products/printed t shirt/14.png"),
			require("../Images/Products/printed t shirt/14.png"),
			require("../Images/Products/printed t shirt/14.png"),
		],
		button: "Enquire",
	},
	{
		id: 90,
		name: "Men Cotton Printed T-Shirt 15",
		price: "Price : $5.5",
		category: "Printed T Shirt",
		productionLeadTime: "Production Lead Time: 35 Days",
		color: "Color: NA",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar: O-Neck",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Technics: "Technics: Printed",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/printed t shirt/15.png"),
			require("../Images/Products/printed t shirt/15.png"),
			require("../Images/Products/printed t shirt/15.png"),
			require("../Images/Products/printed t shirt/15.png"),
			require("../Images/Products/printed t shirt/15.png"),
			require("../Images/Products/printed t shirt/15.png"),
		],
		button: "Enquire",
	},
	{
		id: 91,
		name: "Men Cotton Printed T-Shirt 16",
		price: "Price : $5.5",
		category: "Printed T Shirt",
		productionLeadTime: "Production Lead Time: 35 Days",
		color: "Color: NA",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar: O-Neck",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Technics: "Technics: Printed",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/printed t shirt/16.png"),
			require("../Images/Products/printed t shirt/16.png"),
			require("../Images/Products/printed t shirt/16.png"),
			require("../Images/Products/printed t shirt/16.png"),
			require("../Images/Products/printed t shirt/16.png"),
			require("../Images/Products/printed t shirt/16.png"),
		],
		button: "Enquire",
	},
	{
		id: 92,
		name: "Men Cotton Printed T-Shirt 17",
		price: "Price : $5.5",
		category: "Printed T Shirt",
		productionLeadTime: "Production Lead Time: 35 Days",
		color: "Color: NA",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar: O-Neck",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Technics: "Technics: Printed",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/printed t shirt/17.png"),
			require("../Images/Products/printed t shirt/17.png"),
			require("../Images/Products/printed t shirt/17.png"),
			require("../Images/Products/printed t shirt/17.png"),
			require("../Images/Products/printed t shirt/17.png"),
			require("../Images/Products/printed t shirt/17.png"),
		],
		button: "Enquire",
	},
	{
		id: 93,
		name: "Men Cotton Printed T-Shirt 18",
		price: "Price : $5.5",
		category: "Printed T Shirt",
		productionLeadTime: "Production Lead Time: 35 Days",
		color: "Color: NA",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar: O-Neck",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Technics: "Technics: Printed",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/printed t shirt/18.png"),
			require("../Images/Products/printed t shirt/18.png"),
			require("../Images/Products/printed t shirt/18.png"),
			require("../Images/Products/printed t shirt/18.png"),
			require("../Images/Products/printed t shirt/18.png"),
			require("../Images/Products/printed t shirt/18.png"),
		],
		button: "Enquire",
	},
	{
		id: 94,
		name: "Men Cotton Printed T-Shirt 19",
		price: "Price : $5.8",
		category: "Printed T Shirt",
		productionLeadTime: "Production Lead Time: 35 Days",
		color: "Color: NA",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar: O-Neck",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Technics: "Technics: Printed",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/printed t shirt/19.png"),
			require("../Images/Products/printed t shirt/19.png"),
			require("../Images/Products/printed t shirt/19.png"),
			require("../Images/Products/printed t shirt/19.png"),
			require("../Images/Products/printed t shirt/19.png"),
			require("../Images/Products/printed t shirt/19.png"),
		],
		button: "Enquire",
	},
	{
		id: 95,
		name: "Men Cotton Printed T-Shirt 20",
		price: "Price : $5.5",
		category: "Printed T Shirt",
		productionLeadTime: "Production Lead Time: 35 Days",
		color: "Color: NA",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar: O-Neck",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Technics: "Technics: Printed",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/printed t shirt/20.png"),
			require("../Images/Products/printed t shirt/20.png"),
			require("../Images/Products/printed t shirt/20.png"),
			require("../Images/Products/printed t shirt/20.png"),
			require("../Images/Products/printed t shirt/20.png"),
			require("../Images/Products/printed t shirt/20.png"),
		],
		button: "Enquire",
	},
	{
		id: 96,
		name: "Men Cotton Printed T-Shirt 21",
		price: "Price : $5.88",
		category: "Printed T Shirt",
		productionLeadTime: "Production Lead Time: 35 Days",
		color: "Color: NA",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar: O-Neck",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Technics: "Technics: Printed",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/printed t shirt/21.png"),
			require("../Images/Products/printed t shirt/21.png"),
			require("../Images/Products/printed t shirt/21.png"),
			require("../Images/Products/printed t shirt/21.png"),
			require("../Images/Products/printed t shirt/21.png"),
			require("../Images/Products/printed t shirt/21.png"),
		],
		button: "Enquire",
	},
	{
		id: 97,
		name: "Men Cotton Printed T-Shirt 22",
		price: "Price : $5.8",
		category: "Printed T Shirt",
		productionLeadTime: "Production Lead Time: 35 Days",
		color: "Color: NA",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar: O-Neck",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Technics: "Technics: Printed",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/printed t shirt/22.png"),
			require("../Images/Products/printed t shirt/22.png"),
			require("../Images/Products/printed t shirt/22.png"),
			require("../Images/Products/printed t shirt/22.png"),
			require("../Images/Products/printed t shirt/22.png"),
			require("../Images/Products/printed t shirt/22.png"),
		],
		button: "Enquire",
	},
	{
		id: 98,
		name: "Men Cotton Printed T-Shirt 23",
		price: "Price : $5.8",
		category: "Printed T Shirt",
		productionLeadTime: "Production Lead Time: 35 Days",
		color: "Color: NA",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar: O-Neck",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Technics: "Technics: Printed",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/printed t shirt/23.png"),
			require("../Images/Products/printed t shirt/23.png"),
			require("../Images/Products/printed t shirt/23.png"),
			require("../Images/Products/printed t shirt/23.png"),
			require("../Images/Products/printed t shirt/23.png"),
			require("../Images/Products/printed t shirt/23.png"),
		],
		button: "Enquire",
	},
	{
		id: 99,
		name: "Men Cotton Printed T-Shirt 24",
		price: "Price : $5.9",
		category: "Printed T Shirt",
		productionLeadTime: "Production Lead Time: 35 Days",
		color: "Color: NA",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar: O-Neck",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Technics: "Technics: Printed",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/printed t shirt/24.png"),
			require("../Images/Products/printed t shirt/24.png"),
			require("../Images/Products/printed t shirt/24.png"),
			require("../Images/Products/printed t shirt/24.png"),
			require("../Images/Products/printed t shirt/24.png"),
			require("../Images/Products/printed t shirt/24.png"),
		],
		button: "Enquire",
	},
	{
		id: 100,
		name: "Men Cotton Printed T-Shirt 25",
		price: "Price : $5.5",
		category: "Printed T Shirt",
		productionLeadTime: "Production Lead Time: 35 Days",
		color: "Color: NA",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar: O-Neck",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Technics: "Technics: Printed",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/printed t shirt/25.png"),
			require("../Images/Products/printed t shirt/25.png"),
			require("../Images/Products/printed t shirt/25.png"),
			require("../Images/Products/printed t shirt/25.png"),
			require("../Images/Products/printed t shirt/25.png"),
			require("../Images/Products/printed t shirt/25.png"),
		],
		button: "Enquire",
	},
	{
		id: 101,
		name: "Men Cotton Printed T-Shirt 26",
		price: "Price : $5.9",
		category: "Printed T Shirt",
		productionLeadTime: "Production Lead Time: 35 Days",
		color: "Color: NA",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar: O-Neck",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Technics: "Technics: Printed",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/printed t shirt/26.png"),
			require("../Images/Products/printed t shirt/26.png"),
			require("../Images/Products/printed t shirt/26.png"),
			require("../Images/Products/printed t shirt/26.png"),
			require("../Images/Products/printed t shirt/26.png"),
			require("../Images/Products/printed t shirt/26.png"),
		],
		button: "Enquire",
	},
	{
		id: 102,
		name: "Men Cotton Printed T-Shirt 27",
		price: "Price : $5.8",
		category: "Printed T Shirt",
		productionLeadTime: "Production Lead Time: 35 Days",
		color: "Color: NA",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar: O-Neck",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Technics: "Technics: Printed",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/printed t shirt/27.png"),
			require("../Images/Products/printed t shirt/27.png"),
			require("../Images/Products/printed t shirt/27.png"),
			require("../Images/Products/printed t shirt/27.png"),
			require("../Images/Products/printed t shirt/27.png"),
			require("../Images/Products/printed t shirt/27.png"),
		],
		button: "Enquire",
	},
	{
		id: 103,
		name: "Men Cotton Printed T-Shirt 28",
		price: "Price : $5.8",
		category: "Printed T Shirt",
		productionLeadTime: "Production Lead Time: 35 Days",
		color: "Color: NA",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar: O-Neck",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Technics: "Technics: Printed",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/printed t shirt/28.png"),
			require("../Images/Products/printed t shirt/28.png"),
			require("../Images/Products/printed t shirt/28.png"),
			require("../Images/Products/printed t shirt/28.png"),
			require("../Images/Products/printed t shirt/28.png"),
			require("../Images/Products/printed t shirt/28.png"),
		],
		button: "Enquire",
	},
	{
		id: 104,
		name: "Men Cotton Printed T-Shirt 29",
		price: "Price : $5.8",
		category: "Printed T Shirt",
		productionLeadTime: "Production Lead Time: 35 Days",
		color: "Color: NA",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar: O-Neck",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Technics: "Technics: Printed",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/printed t shirt/29.png"),
			require("../Images/Products/printed t shirt/29.png"),
			require("../Images/Products/printed t shirt/29.png"),
			require("../Images/Products/printed t shirt/29.png"),
			require("../Images/Products/printed t shirt/29.png"),
			require("../Images/Products/printed t shirt/29.png"),
		],
		button: "Enquire",
	},
	{
		id: 105,
		name: "Men Cotton Printed T-Shirt 30",
		price: "Price : $5.8",
		category: "Printed T Shirt",
		productionLeadTime: "Production Lead Time: 35 Days",
		color: "Color: NA",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar: O-Neck",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Technics: "Technics: Printed",
		Season: "Season: Summer",
		Material: "Material: 100% Cotton",
		Customised: "Customized? YES",
		description:
			"Our premium customizable T Shirt is the perfect blend of style, comfort, and creativity. Made from  soft, breathable cotton, this T Shirt provides all-day comfort, making it ideal for casual wear, workouts, or lounging. Available in a variety of colors and sizes, it's designed to suit your unique personality and taste. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "100% breathable cotton for all-day comfort.",
			customDesign: "Add your own text, logos, or artwork.",
			vibrantPrints: "High-quality, durable prints that won’t crack or fade.",
			unisexFit: "100% breathable cotton for all-day comfort.",
			multipleColors: "Choose from a variety of color options to match your style.",
			reinforcedStitching: "Durable stitching for long-lasting wear.",
			ecoFriendlyPrinting: "Uses eco-conscious ink and processes.",
			lightweightAndStretchable: "For ease of movement and added comfort.",
		},
		imageUrl: [
			require("../Images/Products/printed t shirt/30.png"),
			require("../Images/Products/printed t shirt/30.png"),
			require("../Images/Products/printed t shirt/30.png"),
			require("../Images/Products/printed t shirt/30.png"),
			require("../Images/Products/printed t shirt/30.png"),
			require("../Images/Products/printed t shirt/30.png"),
		],
		button: "Enquire",
	},
	{
		id: 106,
		name: " Men Printed Kangaroo Hoodie 1",
		price: "Price : $9.6",
		category: "Hoodies",
		productionLeadTime: "Production Lead Time: 40 Days",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar Type: Hooded",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Technics: "Technics: Printed",
		fabricType: "Fabric Type: Fleec",
		Material: "Material: Cotton, Polyester",
		Customised: "Customized? YES",
		description:
			"Our hoodie is a top-rated favorite for its comfort, quality, and style. Made from soft, durable materials, it offers warmth and a great fit. The adjustable hood and roomy pocket enhance its practicality, making it a go-to for any casual occasion. Customers love how it stays cozy and stylish, wash after wash. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			soft: "High-Quality Fabric for ultimate comfort.",
			hood: "Adjustable Hood for a personalized fit.",
			pocket: "Convenient Front Pocket for essentials.",
			durable: "Durable Construction that lasts.",
			design: "Versatile Design ; for any casual look.",
			fit: "Comfortable Fit ; for all-day wear.",
			Breathable: " Breathable Material to keep you cozy without overheating.",
			customDesign:
				"Customizable Options to add your unique touch, including colors, prints, and embroidery.",
		},
		imageUrl: [
			require("../Images/Products/Hoodie/CCPIC/main-hoody.png"),
			require("../Images/Products/Hoodie/CCPIC/2.png"),
			require("../Images/Products/Hoodie/CCPIC/3.png"),
			require("../Images/Products/Hoodie/CCPIC/5.png"),
			require("../Images/Products/Hoodie/CCPIC/6.png"),
			require("../Images/Products/Hoodie/CCPIC/7.png"),
		],
		button: "Enquire",
	},
	{
		id: 107,
		name: " Men Printed Kangaroo Hoodie 2",
		price: "Price : $9.4",
		category: "Hoodies",
		productionLeadTime: "Production Lead Time: 40 Days",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar Type: Hooded",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Technics: "Technics: Printed",
		fabricType: "Fabric Type: Fleec",
		Material: "Material: Cotton, Polyester",
		Customised: "Customized? YES",
		description:
			"Our hoodie is a top-rated favorite for its comfort, quality, and style. Made from soft, durable materials, it offers warmth and a great fit. The adjustable hood and roomy pocket enhance its practicality, making it a go-to for any casual occasion. Customers love how it stays cozy and stylish, wash after wash. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			soft: "High-Quality Fabric for ultimate comfort.",
			hood: "Adjustable Hood for a personalized fit.",
			pocket: "Convenient Front Pocket for essentials.",
			durable: "Durable Construction that lasts.",
			design: "Versatile Design ; for any casual look.",
			fit: "Comfortable Fit ; for all-day wear.",
			Breathable: " Breathable Material to keep you cozy without overheating.",
			customDesign:
				"Customizable Options to add your unique touch, including colors, prints, and embroidery.",
		},
		imageUrl: [
			require("../Images/Products/Hoodie/CCCPIC/main-hoody.png"),
			require("../Images/Products/Hoodie/CCCPIC/2.png"),
			require("../Images/Products/Hoodie/CCCPIC/3.png"),
			require("../Images/Products/Hoodie/CCCPIC/4.png"),
			require("../Images/Products/Hoodie/CCCPIC/6.png"),
			require("../Images/Products/Hoodie/CCCPIC/7.png"),
		],
		button: "Enquire",
	},
	{
		id: 108,
		name: " Men Printed Kangaroo Hoodie 3",
		price: "Price : $9.4",
		category: "Hoodies",
		productionLeadTime: "Production Lead Time: 40 Days",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar Type: Hooded",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Technics: "Technics: Printed",
		fabricType: "Fabric Type: Fleec",
		Material: "Material: Cotton, Polyester",
		Customised: "Customized? YES",
		description:
			"Our hoodie is a top-rated favorite for its comfort, quality, and style. Made from soft, durable materials, it offers warmth and a great fit. The adjustable hood and roomy pocket enhance its practicality, making it a go-to for any casual occasion. Customers love how it stays cozy and stylish, wash after wash. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			soft: "High-Quality Fabric for ultimate comfort.",
			hood: "Adjustable Hood for a personalized fit.",
			pocket: "Convenient Front Pocket for essentials.",
			durable: "Durable Construction that lasts.",
			design: "Versatile Design ; for any casual look.",
			fit: "Comfortable Fit ; for all-day wear.",
			Breathable: " Breathable Material to keep you cozy without overheating.",
			customDesign:
				"Customizable Options to add your unique touch, including colors, prints, and embroidery.",
		},
		imageUrl: [
			require("../Images/Products/Hoodie/DDDPIC/main-hoody.png"),
			require("../Images/Products/Hoodie/DDDPIC/2.png"),
			require("../Images/Products/Hoodie/DDDPIC/4.png"),
			require("../Images/Products/Hoodie/DDDPIC/5.png"),
			require("../Images/Products/Hoodie/DDDPIC/6.png"),
			require("../Images/Products/Hoodie/DDDPIC/7.png"),
		],
		button: "Enquire",
	},
	{
		id: 109,
		name: " Men Printed Kangaroo Hoodie 4",
		price: "Price : $9.6",
		category: "Hoodies",
		productionLeadTime: "Production Lead Time: 40 Days",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar Type: Hooded",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Technics: "Technics: Printed",
		fabricType: "Fabric Type: Fleec",
		Material: "Material: Cotton, Polyester",
		Customised: "Customized? YES",
		description:
			"Our hoodie is a top-rated favorite for its comfort, quality, and style. Made from soft, durable materials, it offers warmth and a great fit. The adjustable hood and roomy pocket enhance its practicality, making it a go-to for any casual occasion. Customers love how it stays cozy and stylish, wash after wash. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			soft: "High-Quality Fabric for ultimate comfort.",
			hood: "Adjustable Hood for a personalized fit.",
			pocket: "Convenient Front Pocket for essentials.",
			durable: "Durable Construction that lasts.",
			design: "Versatile Design ; for any casual look.",
			fit: "Comfortable Fit ; for all-day wear.",
			Breathable: " Breathable Material to keep you cozy without overheating.",
			customDesign:
				"Customizable Options to add your unique touch, including colors, prints, and embroidery.",
		},
		imageUrl: [
			require("../Images/Products/Hoodie/CPIC/main-hoody.png"),
			require("../Images/Products/Hoodie/CPIC/3.png"),
			require("../Images/Products/Hoodie/CPIC/4.png"),
			require("../Images/Products/Hoodie/CPIC/5.png"),
			require("../Images/Products/Hoodie/CPIC/6.png"),
			require("../Images/Products/Hoodie/CPIC/7.png"),
		],
		button: "Enquire",
	},
	{
		id: 110,
		name: " Men Printed Kangaroo Hoodie 5",
		price: "Price : $9.6",
		category: "Hoodies",
		productionLeadTime: "Production Lead Time: 40 Days",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar Type: Hooded",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Technics: "Technics: Printed",
		fabricType: "Fabric Type: Fleec",
		Material: "Material: Cotton, Polyester",
		Customised: "Customized? YES",
		description:
			"Our hoodie is a top-rated favorite for its comfort, quality, and style. Made from soft, durable materials, it offers warmth and a great fit. The adjustable hood and roomy pocket enhance its practicality, making it a go-to for any casual occasion. Customers love how it stays cozy and stylish, wash after wash. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			soft: "High-Quality Fabric for ultimate comfort.",
			hood: "Adjustable Hood for a personalized fit.",
			pocket: "Convenient Front Pocket for essentials.",
			durable: "Durable Construction that lasts.",
			design: "Versatile Design ; for any casual look.",
			fit: "Comfortable Fit ; for all-day wear.",
			Breathable: " Breathable Material to keep you cozy without overheating.",
			customDesign:
				"Customizable Options to add your unique touch, including colors, prints, and embroidery.",
		},
		imageUrl: [
			require("../Images/Products/Hoodie/EEEPIC/main-hoody.png"),
			require("../Images/Products/Hoodie/EEEPIC/2.png"),
			require("../Images/Products/Hoodie/EEEPIC/3.png"),
			require("../Images/Products/Hoodie/EEEPIC/4.png"),
			require("../Images/Products/Hoodie/EEEPIC/5.png"),
			require("../Images/Products/Hoodie/EEEPIC/6.png"),
		],
		button: "Enquire",
	},
	{
		id: 111,
		name: " Men Printed Kangaroo Hoodie 6",
		price: "Price : $9.6",
		category: "Hoodies",
		productionLeadTime: "Production Lead Time: 40 Days",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar Type: Hooded",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Technics: "Technics: Printed",
		fabricType: "Fabric Type: Fleec",
		Material: "Material: Cotton, Polyester",
		Customised: "Customized? YES",
		description:
			"Our hoodie is a top-rated favorite for its comfort, quality, and style. Made from soft, durable materials, it offers warmth and a great fit. The adjustable hood and roomy pocket enhance its practicality, making it a go-to for any casual occasion. Customers love how it stays cozy and stylish, wash after wash. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			soft: "High-Quality Fabric for ultimate comfort.",
			hood: "Adjustable Hood for a personalized fit.",
			pocket: "Convenient Front Pocket for essentials.",
			durable: "Durable Construction that lasts.",
			design: "Versatile Design ; for any casual look.",
			fit: "Comfortable Fit ; for all-day wear.",
			Breathable: " Breathable Material to keep you cozy without overheating.",
			customDesign:
				"Customizable Options to add your unique touch, including colors, prints, and embroidery.",
		},
		imageUrl: [
			require("../Images/Products/Hoodie/EEPIC/main-hoody.png"),
			require("../Images/Products/Hoodie/EEPIC/2.png"),
			require("../Images/Products/Hoodie/EEPIC/3.png"),
			require("../Images/Products/Hoodie/EEPIC/5.png"),
			require("../Images/Products/Hoodie/EEPIC/6.png"),
			require("../Images/Products/Hoodie/EEPIC/7.png"),
		],
		button: "Enquire",
	},
	{
		id: 112,
		name: " Men Printed Kangaroo Hoodie 7",
		price: "Price : $9.6",
		category: "Hoodies",
		productionLeadTime: "Production Lead Time: 40 Days",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar Type: Hooded",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Technics: "Technics: Printed",
		fabricType: "Fabric Type: Fleec",
		Material: "Material: Cotton, Polyester",
		Customised: "Customized? YES",
		description:
			"Our hoodie is a top-rated favorite for its comfort, quality, and style. Made from soft, durable materials, it offers warmth and a great fit. The adjustable hood and roomy pocket enhance its practicality, making it a go-to for any casual occasion. Customers love how it stays cozy and stylish, wash after wash. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			soft: "High-Quality Fabric for ultimate comfort.",
			hood: "Adjustable Hood for a personalized fit.",
			pocket: "Convenient Front Pocket for essentials.",
			durable: "Durable Construction that lasts.",
			design: "Versatile Design ; for any casual look.",
			fit: "Comfortable Fit ; for all-day wear.",
			Breathable: " Breathable Material to keep you cozy without overheating.",
			customDesign:
				"Customizable Options to add your unique touch, including colors, prints, and embroidery.",
		},
		imageUrl: [
			require("../Images/Products/Hoodie/FFFPIC/main-hoody.png"),
			require("../Images/Products/Hoodie/FFFPIC/2.png"),
			require("../Images/Products/Hoodie/FFFPIC/3.png"),
			require("../Images/Products/Hoodie/FFFPIC/4.png"),
			require("../Images/Products/Hoodie/FFFPIC/6.png"),
			require("../Images/Products/Hoodie/FFFPIC/7.png"),
		],
		button: "Enquire",
	},
	{
		id: 113,
		name: " Men Printed Kangaroo Hoodie 8",
		price: "Price : $9.6",
		category: "Hoodies",
		productionLeadTime: "Production Lead Time: 40 Days",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar Type: Hooded",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Technics: "Technics: Printed",
		fabricType: "Fabric Type: Fleec",
		Material: "Material: Cotton, Polyester",
		Customised: "Customized? YES",
		description:
			"Our hoodie is a top-rated favorite for its comfort, quality, and style. Made from soft, durable materials, it offers warmth and a great fit. The adjustable hood and roomy pocket enhance its practicality, making it a go-to for any casual occasion. Customers love how it stays cozy and stylish, wash after wash. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			soft: "High-Quality Fabric for ultimate comfort.",
			hood: "Adjustable Hood for a personalized fit.",
			pocket: "Convenient Front Pocket for essentials.",
			durable: "Durable Construction that lasts.",
			design: "Versatile Design ; for any casual look.",
			fit: "Comfortable Fit ; for all-day wear.",
			Breathable: " Breathable Material to keep you cozy without overheating.",
			customDesign:
				"Customizable Options to add your unique touch, including colors, prints, and embroidery.",
		},
		imageUrl: [
			require("../Images/Products/Hoodie/FPIC/main-hoody.png"),
			require("../Images/Products/Hoodie/FPIC/3.png"),
			require("../Images/Products/Hoodie/FPIC/4.png"),
			require("../Images/Products/Hoodie/FPIC/5.png"),
			require("../Images/Products/Hoodie/FPIC/6.png"),
			require("../Images/Products/Hoodie/FPIC/7.png"),
		],
		button: "Enquire",
	},
	{
		id: 114,
		name: " Men Printed Kangaroo Hoodie 9",
		price: "Price : $9.6",
		category: "Hoodies",
		productionLeadTime: "Production Lead Time: 40 Days",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar Type: Hooded",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Technics: "Technics: Printed",
		fabricType: "Fabric Type: Fleec",
		Material: "Material: Cotton, Polyester",
		Customised: "Customized? YES",
		description:
			"Our hoodie is a top-rated favorite for its comfort, quality, and style. Made from soft, durable materials, it offers warmth and a great fit. The adjustable hood and roomy pocket enhance its practicality, making it a go-to for any casual occasion. Customers love how it stays cozy and stylish, wash after wash. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			soft: "High-Quality Fabric for ultimate comfort.",
			hood: "Adjustable Hood for a personalized fit.",
			pocket: "Convenient Front Pocket for essentials.",
			durable: "Durable Construction that lasts.",
			design: "Versatile Design ; for any casual look.",
			fit: "Comfortable Fit ; for all-day wear.",
			Breathable: " Breathable Material to keep you cozy without overheating.",
			customDesign:
				"Customizable Options to add your unique touch, including colors, prints, and embroidery.",
		},
		imageUrl: [
			require("../Images/Products/Hoodie/GGGPIC/main-hoody.png"),
			require("../Images/Products/Hoodie/GGGPIC/2.png"),
			require("../Images/Products/Hoodie/GGGPIC/3.png"),
			require("../Images/Products/Hoodie/GGGPIC/4.png"),
			require("../Images/Products/Hoodie/GGGPIC/6.png"),
			require("../Images/Products/Hoodie/GGGPIC/7.png"),
		],
		button: "Enquire",
	},
	{
		id: 115,
		name: " Men Printed Kangaroo Hoodie 10",
		price: "Price : $9.4",
		category: "Hoodies",
		productionLeadTime: "Production Lead Time: 40 Days",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar Type: Hooded",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Technics: "Technics: Printed",
		fabricType: "Fabric Type: Fleec",
		Material: "Material: Cotton, Polyester",
		Customised: "Customized? YES",
		description:
			"Our hoodie is a top-rated favorite for its comfort, quality, and style. Made from soft, durable materials, it offers warmth and a great fit. The adjustable hood and roomy pocket enhance its practicality, making it a go-to for any casual occasion. Customers love how it stays cozy and stylish, wash after wash. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			soft: "High-Quality Fabric for ultimate comfort.",
			hood: "Adjustable Hood for a personalized fit.",
			pocket: "Convenient Front Pocket for essentials.",
			durable: "Durable Construction that lasts.",
			design: "Versatile Design ; for any casual look.",
			fit: "Comfortable Fit ; for all-day wear.",
			Breathable: " Breathable Material to keep you cozy without overheating.",
			customDesign:
				"Customizable Options to add your unique touch, including colors, prints, and embroidery.",
		},
		imageUrl: [
			require("../Images/Products/Hoodie/GGPIC/main-hoody.png"),
			require("../Images/Products/Hoodie/GGPIC/2.png"),
			require("../Images/Products/Hoodie/GGPIC/3.png"),
			require("../Images/Products/Hoodie/GGPIC/4.png"),
			require("../Images/Products/Hoodie/GGPIC/6.png"),
			require("../Images/Products/Hoodie/GGPIC/7.png"),
		],
		button: "Enquire",
	},
	{
		id: 116,
		name: " Men Printed Kangaroo Hoodie 11",
		price: "Price : $9.4",
		category: "Hoodies",
		productionLeadTime: "Production Lead Time: 40 Days",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar Type: Hooded",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Technics: "Technics: Printed",
		fabricType: "Fabric Type: Fleec",
		Material: "Material: Cotton, Polyester",
		Customised: "Customized? YES",
		description:
			"Our hoodie is a top-rated favorite for its comfort, quality, and style. Made from soft, durable materials, it offers warmth and a great fit. The adjustable hood and roomy pocket enhance its practicality, making it a go-to for any casual occasion. Customers love how it stays cozy and stylish, wash after wash. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			soft: "High-Quality Fabric for ultimate comfort.",
			hood: "Adjustable Hood for a personalized fit.",
			pocket: "Convenient Front Pocket for essentials.",
			durable: "Durable Construction that lasts.",
			design: "Versatile Design ; for any casual look.",
			fit: "Comfortable Fit ; for all-day wear.",
			Breathable: " Breathable Material to keep you cozy without overheating.",
			customDesign:
				"Customizable Options to add your unique touch, including colors, prints, and embroidery.",
		},
		imageUrl: [
			require("../Images/Products/Hoodie/H1.0/main-hoody.png"),
			require("../Images/Products/Hoodie/H1.0/mini-H1.0.png"),
			require("../Images/Products/Hoodie/H1.0/mini-H1.2.png"),
			require("../Images/Products/Hoodie/H1.0/mini-H1.3.png"),
			require("../Images/Products/Hoodie/H1.0/mini-H1.4.png"),
			require("../Images/Products/Hoodie/H1.0/mini-H1.5.png"),
		],
		button: "Enquire",
	},
	{
		id: 117,
		name: " Men Printed Kangaroo Hoodie 12",
		price: "Price : $9.4",
		category: "Hoodies",
		productionLeadTime: "Production Lead Time: 40 Days",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar Type: Hooded",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Technics: "Technics: Printed",
		fabricType: "Fabric Type: Fleec",
		Material: "Material: Cotton, Polyester",
		Customised: "Customized? YES",
		description:
			"Our hoodie is a top-rated favorite for its comfort, quality, and style. Made from soft, durable materials, it offers warmth and a great fit. The adjustable hood and roomy pocket enhance its practicality, making it a go-to for any casual occasion. Customers love how it stays cozy and stylish, wash after wash. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			soft: "High-Quality Fabric for ultimate comfort.",
			hood: "Adjustable Hood for a personalized fit.",
			pocket: "Convenient Front Pocket for essentials.",
			durable: "Durable Construction that lasts.",
			design: "Versatile Design ; for any casual look.",
			fit: "Comfortable Fit ; for all-day wear.",
			Breathable: " Breathable Material to keep you cozy without overheating.",
			customDesign:
				"Customizable Options to add your unique touch, including colors, prints, and embroidery.",
		},
		imageUrl: [
			require("../Images/Products/Hoodie/HHHPIC/main-hoody.png"),
			require("../Images/Products/Hoodie/HHHPIC/2.png"),
			require("../Images/Products/Hoodie/HHHPIC/4.png"),
			require("../Images/Products/Hoodie/HHHPIC/5.png"),
			require("../Images/Products/Hoodie/HHHPIC/6.png"),
			require("../Images/Products/Hoodie/HHHPIC/7.png"),
		],
		button: "Enquire",
	},
	{
		id: 118,
		name: " Men Printed Kangaroo Hoodie 13",
		price: "Price : $9.4",
		category: "Hoodies",
		productionLeadTime: "Production Lead Time: 40 Days",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar Type: Hooded",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Technics: "Technics: Printed",
		fabricType: "Fabric Type: Fleec",
		Material: "Material: Cotton, Polyester",
		Customised: "Customized? YES",
		description:
			"Our hoodie is a top-rated favorite for its comfort, quality, and style. Made from soft, durable materials, it offers warmth and a great fit. The adjustable hood and roomy pocket enhance its practicality, making it a go-to for any casual occasion. Customers love how it stays cozy and stylish, wash after wash. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			soft: "High-Quality Fabric for ultimate comfort.",
			hood: "Adjustable Hood for a personalized fit.",
			pocket: "Convenient Front Pocket for essentials.",
			durable: "Durable Construction that lasts.",
			design: "Versatile Design ; for any casual look.",
			fit: "Comfortable Fit ; for all-day wear.",
			Breathable: " Breathable Material to keep you cozy without overheating.",
			customDesign:
				"Customizable Options to add your unique touch, including colors, prints, and embroidery.",
		},
		imageUrl: [
			require("../Images/Products/Hoodie/IIPIC/main-hoody.png"),
			require("../Images/Products/Hoodie/IIPIC/2.png"),
			require("../Images/Products/Hoodie/IIPIC/3.png"),
			require("../Images/Products/Hoodie/IIPIC/5.png"),
			require("../Images/Products/Hoodie/IIPIC/2.png"),
			require("../Images/Products/Hoodie/IIPIC/7.png"),
		],
		button: "Enquire",
	},
	{
		id: 119,
		name: " Men Printed Kangaroo Hoodie 14",
		price: "Price : $9.6",
		category: "Hoodies",
		productionLeadTime: "Production Lead Time: 40 Days",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar Type: Hooded",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Technics: "Technics: Printed",
		fabricType: "Fabric Type: Fleec",
		Material: "Material: Cotton, Polyester",
		Customised: "Customized? YES",
		description:
			"Our hoodie is a top-rated favorite for its comfort, quality, and style. Made from soft, durable materials, it offers warmth and a great fit. The adjustable hood and roomy pocket enhance its practicality, making it a go-to for any casual occasion. Customers love how it stays cozy and stylish, wash after wash. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			soft: "High-Quality Fabric for ultimate comfort.",
			hood: "Adjustable Hood for a personalized fit.",
			pocket: "Convenient Front Pocket for essentials.",
			durable: "Durable Construction that lasts.",
			design: "Versatile Design ; for any casual look.",
			fit: "Comfortable Fit ; for all-day wear.",
			Breathable: " Breathable Material to keep you cozy without overheating.",
			customDesign:
				"Customizable Options to add your unique touch, including colors, prints, and embroidery.",
		},
		imageUrl: [
			require("../Images/Products/Hoodie/JJJPIC/main-hoody.png"),
			require("../Images/Products/Hoodie/JJJPIC/3.png"),
			require("../Images/Products/Hoodie/JJJPIC/4.png"),
			require("../Images/Products/Hoodie/JJJPIC/5.png"),
			require("../Images/Products/Hoodie/JJJPIC/6.png"),
			require("../Images/Products/Hoodie/JJJPIC/3.png"),
		],
		button: "Enquire",
	},
	{
		id: 120,
		name: " Men Printed Kangaroo Hoodie 15",
		price: "Price : $9.4",
		category: "Hoodies",
		productionLeadTime: "Production Lead Time: 40 Days",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar Type: Hooded",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Technics: "Technics: Printed",
		fabricType: "Fabric Type: Fleec",
		Material: "Material: Cotton, Polyester",
		Customised: "Customized? YES",
		description:
			"Our hoodie is a top-rated favorite for its comfort, quality, and style. Made from soft, durable materials, it offers warmth and a great fit. The adjustable hood and roomy pocket enhance its practicality, making it a go-to for any casual occasion. Customers love how it stays cozy and stylish, wash after wash. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			soft: "High-Quality Fabric for ultimate comfort.",
			hood: "Adjustable Hood for a personalized fit.",
			pocket: "Convenient Front Pocket for essentials.",
			durable: "Durable Construction that lasts.",
			design: "Versatile Design ; for any casual look.",
			fit: "Comfortable Fit ; for all-day wear.",
			Breathable: " Breathable Material to keep you cozy without overheating.",
			customDesign:
				"Customizable Options to add your unique touch, including colors, prints, and embroidery.",
		},
		imageUrl: [
			require("../Images/Products/Hoodie/LLLPIC/main-hoody.png"),
			require("../Images/Products/Hoodie/LLLPIC/2.png"),
			require("../Images/Products/Hoodie/LLLPIC/3.png"),
			require("../Images/Products/Hoodie/LLLPIC/4.png"),
			require("../Images/Products/Hoodie/LLLPIC/5.png"),
			require("../Images/Products/Hoodie/LLLPIC/7.png"),
		],
		button: "Enquire",
	},
	{
		id: 121,
		name: " Men Printed Kangaroo Hoodie 16",
		price: "Price : $9.6",
		category: "Hoodies",
		productionLeadTime: "Production Lead Time: 40 Days",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar Type: Hooded",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Technics: "Technics: Printed",
		fabricType: "Fabric Type: Fleec",
		Material: "Material: Cotton, Polyester",
		Customised: "Customized? YES",
		description:
			"Our hoodie is a top-rated favorite for its comfort, quality, and style. Made from soft, durable materials, it offers warmth and a great fit. The adjustable hood and roomy pocket enhance its practicality, making it a go-to for any casual occasion. Customers love how it stays cozy and stylish, wash after wash. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			soft: "High-Quality Fabric for ultimate comfort.",
			hood: "Adjustable Hood for a personalized fit.",
			pocket: "Convenient Front Pocket for essentials.",
			durable: "Durable Construction that lasts.",
			design: "Versatile Design ; for any casual look.",
			fit: "Comfortable Fit ; for all-day wear.",
			Breathable: " Breathable Material to keep you cozy without overheating.",
			customDesign:
				"Customizable Options to add your unique touch, including colors, prints, and embroidery.",
		},
		imageUrl: [
			require("../Images/Products/Hoodie/MMMPIC/main-hoody.png"),
			require("../Images/Products/Hoodie/MMMPIC/3.png"),
			require("../Images/Products/Hoodie/MMMPIC/4.png"),
			require("../Images/Products/Hoodie/MMMPIC/5.png"),
			require("../Images/Products/Hoodie/MMMPIC/6.png"),
			require("../Images/Products/Hoodie/MMMPIC/3.png"),
		],
		button: "Enquire",
	},
	{
		id: 122,
		name: " Men Printed Kangaroo Hoodie 17",
		price: "Price : $9.6",
		category: "Hoodies",
		productionLeadTime: "Production Lead Time: 40 Days",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar Type: Hooded",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Technics: "Technics: Printed",
		fabricType: "Fabric Type: Fleec",
		Material: "Material: Cotton, Polyester",
		Customised: "Customized? YES",
		description:
			"Our hoodie is a top-rated favorite for its comfort, quality, and style. Made from soft, durable materials, it offers warmth and a great fit. The adjustable hood and roomy pocket enhance its practicality, making it a go-to for any casual occasion. Customers love how it stays cozy and stylish, wash after wash. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			soft: "High-Quality Fabric for ultimate comfort.",
			hood: "Adjustable Hood for a personalized fit.",
			pocket: "Convenient Front Pocket for essentials.",
			durable: "Durable Construction that lasts.",
			design: "Versatile Design ; for any casual look.",
			fit: "Comfortable Fit ; for all-day wear.",
			Breathable: " Breathable Material to keep you cozy without overheating.",
			customDesign:
				"Customizable Options to add your unique touch, including colors, prints, and embroidery.",
		},
		imageUrl: [
			require("../Images/Products/Hoodie/NNNPIC/main-hoody.png"),
			require("../Images/Products/Hoodie/NNNPIC/3.png"),
			require("../Images/Products/Hoodie/NNNPIC/4.png"),
			require("../Images/Products/Hoodie/NNNPIC/5.png"),
			require("../Images/Products/Hoodie/NNNPIC/6.png"),
			require("../Images/Products/Hoodie/NNNPIC/7.png"),
		],
		button: "Enquire",
	},
	{
		id: 123,
		name: " Men Printed Kangaroo Hoodie 18",
		price: "Price : $9.4",
		category: "Hoodies",
		productionLeadTime: "Production Lead Time: 40 Days",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar Type: Hooded",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Technics: "Technics: Printed",
		fabricType: "Fabric Type: Fleec",
		Material: "Material: Cotton, Polyester",
		Customised: "Customized? YES",
		description:
			"Our hoodie is a top-rated favorite for its comfort, quality, and style. Made from soft, durable materials, it offers warmth and a great fit. The adjustable hood and roomy pocket enhance its practicality, making it a go-to for any casual occasion. Customers love how it stays cozy and stylish, wash after wash. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			soft: "High-Quality Fabric for ultimate comfort.",
			hood: "Adjustable Hood for a personalized fit.",
			pocket: "Convenient Front Pocket for essentials.",
			durable: "Durable Construction that lasts.",
			design: "Versatile Design ; for any casual look.",
			fit: "Comfortable Fit ; for all-day wear.",
			Breathable: " Breathable Material to keep you cozy without overheating.",
			customDesign:
				"Customizable Options to add your unique touch, including colors, prints, and embroidery.",
		},
		imageUrl: [
			require("../Images/Products/Hoodie/NNPIC/main-hoody.png"),
			require("../Images/Products/Hoodie/NNPIC/3.png"),
			require("../Images/Products/Hoodie/NNPIC/4.png"),
			require("../Images/Products/Hoodie/NNPIC/5.png"),
			require("../Images/Products/Hoodie/NNPIC/6.png"),
			require("../Images/Products/Hoodie/NNPIC/7.png"),
		],
		button: "Enquire",
	},
	{
		id: 124,
		name: " Men Printed Kangaroo Hoodie 19",
		price: "Price : $9.4",
		category: "Hoodies",
		productionLeadTime: "Production Lead Time: 40 Days",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar Type: Hooded",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Technics: "Technics: Printed",
		fabricType: "Fabric Type: Fleec",
		Material: "Material: Cotton, Polyester",
		Customised: "Customized? YES",
		description:
			"Our hoodie is a top-rated favorite for its comfort, quality, and style. Made from soft, durable materials, it offers warmth and a great fit. The adjustable hood and roomy pocket enhance its practicality, making it a go-to for any casual occasion. Customers love how it stays cozy and stylish, wash after wash. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			soft: "High-Quality Fabric for ultimate comfort.",
			hood: "Adjustable Hood for a personalized fit.",
			pocket: "Convenient Front Pocket for essentials.",
			durable: "Durable Construction that lasts.",
			design: "Versatile Design ; for any casual look.",
			fit: "Comfortable Fit ; for all-day wear.",
			Breathable: " Breathable Material to keep you cozy without overheating.",
			customDesign:
				"Customizable Options to add your unique touch, including colors, prints, and embroidery.",
		},
		imageUrl: [
			require("../Images/Products/Hoodie/OOOPIC/main-hoody.png"),
			require("../Images/Products/Hoodie/OOOPIC/3.png"),
			require("../Images/Products/Hoodie/OOOPIC/4.png"),
			require("../Images/Products/Hoodie/OOOPIC/2.png"),
			require("../Images/Products/Hoodie/OOOPIC/6.png"),
			require("../Images/Products/Hoodie/OOOPIC/7.png"),
		],
		button: "Enquire",
	},
	{
		id: 125,
		name: " Men Printed Kangaroo Hoodie 20",
		price: "Price : $9.4",
		category: "Hoodies",
		productionLeadTime: "Production Lead Time: 40 Days",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar Type: Hooded",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Technics: "Technics: Printed",
		fabricType: "Fabric Type: Fleec",
		Material: "Material: Cotton, Polyester",
		Customised: "Customized? YES",
		description:
			"Our hoodie is a top-rated favorite for its comfort, quality, and style. Made from soft, durable materials, it offers warmth and a great fit. The adjustable hood and roomy pocket enhance its practicality, making it a go-to for any casual occasion. Customers love how it stays cozy and stylish, wash after wash. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			soft: "High-Quality Fabric for ultimate comfort.",
			hood: "Adjustable Hood for a personalized fit.",
			pocket: "Convenient Front Pocket for essentials.",
			durable: "Durable Construction that lasts.",
			design: "Versatile Design ; for any casual look.",
			fit: "Comfortable Fit ; for all-day wear.",
			Breathable: " Breathable Material to keep you cozy without overheating.",
			customDesign:
				"Customizable Options to add your unique touch, including colors, prints, and embroidery.",
		},
		imageUrl: [
			require("../Images/Products/Hoodie/OOPIC/main-hoody.png"),
			require("../Images/Products/Hoodie/OOPIC/3.png"),
			require("../Images/Products/Hoodie/OOPIC/5.png"),
			require("../Images/Products/Hoodie/OOPIC/2.png"),
			require("../Images/Products/Hoodie/OOPIC/6.png"),
			require("../Images/Products/Hoodie/OOPIC/7.png"),
		],
		button: "Enquire",
	},
	{
		id: 126,
		name: " Men Printed Kangaroo Hoodie 21",
		price: "Price : $9.4",
		category: "Hoodies",
		productionLeadTime: "Production Lead Time: 40 Days",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar Type: Hooded",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Technics: "Technics: Printed",
		fabricType: "Fabric Type: Fleec",
		Material: "Material: Cotton, Polyester",
		Customised: "Customized? YES",
		description:
			"Our hoodie is a top-rated favorite for its comfort, quality, and style. Made from soft, durable materials, it offers warmth and a great fit. The adjustable hood and roomy pocket enhance its practicality, making it a go-to for any casual occasion. Customers love how it stays cozy and stylish, wash after wash. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			soft: "High-Quality Fabric for ultimate comfort.",
			hood: "Adjustable Hood for a personalized fit.",
			pocket: "Convenient Front Pocket for essentials.",
			durable: "Durable Construction that lasts.",
			design: "Versatile Design ; for any casual look.",
			fit: "Comfortable Fit ; for all-day wear.",
			Breathable: " Breathable Material to keep you cozy without overheating.",
			customDesign:
				"Customizable Options to add your unique touch, including colors, prints, and embroidery.",
		},
		imageUrl: [
			require("../Images/Products/Hoodie/OPIC/main-hoody.png"),
			require("../Images/Products/Hoodie/OPIC/3.png"),
			require("../Images/Products/Hoodie/OPIC/5.png"),
			require("../Images/Products/Hoodie/OPIC/2.png"),
			require("../Images/Products/Hoodie/OPIC/6.png"),
			require("../Images/Products/Hoodie/OPIC/4.png"),
		],
		button: "Enquire",
	},
	{
		id: 127,
		name: " Men Printed Kangaroo Hoodie 22",
		price: "Price : $9.4",
		category: "Hoodies",
		productionLeadTime: "Production Lead Time: 40 Days",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar Type: Hooded",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Technics: "Technics: Printed",
		fabricType: "Fabric Type: Fleec",
		Material: "Material: Cotton, Polyester",
		Customised: "Customized? YES",
		description:
			"Our hoodie is a top-rated favorite for its comfort, quality, and style. Made from soft, durable materials, it offers warmth and a great fit. The adjustable hood and roomy pocket enhance its practicality, making it a go-to for any casual occasion. Customers love how it stays cozy and stylish, wash after wash. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			soft: "High-Quality Fabric for ultimate comfort.",
			hood: "Adjustable Hood for a personalized fit.",
			pocket: "Convenient Front Pocket for essentials.",
			durable: "Durable Construction that lasts.",
			design: "Versatile Design ; for any casual look.",
			fit: "Comfortable Fit ; for all-day wear.",
			Breathable: " Breathable Material to keep you cozy without overheating.",
			customDesign:
				"Customizable Options to add your unique touch, including colors, prints, and embroidery.",
		},
		imageUrl: [
			require("../Images/Products/Hoodie/PPPIC/main-hoody.png"),
			require("../Images/Products/Hoodie/PPPIC/7.png"),
			require("../Images/Products/Hoodie/PPPIC/5.png"),
			require("../Images/Products/Hoodie/PPPIC/2.png"),
			require("../Images/Products/Hoodie/PPPIC/6.png"),
			require("../Images/Products/Hoodie/PPPIC/4.png"),
		],
		button: "Enquire",
	},
	{
		id: 128,
		name: " Men Printed Kangaroo Hoodie 23",
		price: "Price : $9.4",
		category: "Hoodies",
		productionLeadTime: "Production Lead Time: 40 Days",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar Type: Hooded",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Technics: "Technics: Printed",
		fabricType: "Fabric Type: Fleec",
		Material: "Material: Cotton, Polyester",
		Customised: "Customized? YES",
		description:
			"Our hoodie is a top-rated favorite for its comfort, quality, and style. Made from soft, durable materials, it offers warmth and a great fit. The adjustable hood and roomy pocket enhance its practicality, making it a go-to for any casual occasion. Customers love how it stays cozy and stylish, wash after wash. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			soft: "High-Quality Fabric for ultimate comfort.",
			hood: "Adjustable Hood for a personalized fit.",
			pocket: "Convenient Front Pocket for essentials.",
			durable: "Durable Construction that lasts.",
			design: "Versatile Design ; for any casual look.",
			fit: "Comfortable Fit ; for all-day wear.",
			Breathable: " Breathable Material to keep you cozy without overheating.",
			customDesign:
				"Customizable Options to add your unique touch, including colors, prints, and embroidery.",
		},
		imageUrl: [
			require("../Images/Products/Hoodie/PPPPIC/main-hoody.png"),
			require("../Images/Products/Hoodie/PPPPIC/7.png"),
			require("../Images/Products/Hoodie/PPPPIC/5.png"),
			require("../Images/Products/Hoodie/PPPPIC/2.png"),
			require("../Images/Products/Hoodie/PPPPIC/6.png"),
			require("../Images/Products/Hoodie/PPPPIC/3.png"),
		],
		button: "Enquire",
	},
	{
		id: 129,
		name: " Men Printed Kangaroo Hoodie 24",
		price: "Price : $9.4",
		category: "Hoodies",
		productionLeadTime: "Production Lead Time: 40 Days",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar Type: Hooded",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Technics: "Technics: Printed",
		fabricType: "Fabric Type: Fleec",
		Material: "Material: Cotton, Polyester",
		Customised: "Customized? YES",
		description:
			"Our hoodie is a top-rated favorite for its comfort, quality, and style. Made from soft, durable materials, it offers warmth and a great fit. The adjustable hood and roomy pocket enhance its practicality, making it a go-to for any casual occasion. Customers love how it stays cozy and stylish, wash after wash. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			soft: "High-Quality Fabric for ultimate comfort.",
			hood: "Adjustable Hood for a personalized fit.",
			pocket: "Convenient Front Pocket for essentials.",
			durable: "Durable Construction that lasts.",
			design: "Versatile Design ; for any casual look.",
			fit: "Comfortable Fit ; for all-day wear.",
			Breathable: " Breathable Material to keep you cozy without overheating.",
			customDesign:
				"Customizable Options to add your unique touch, including colors, prints, and embroidery.",
		},
		imageUrl: [
			require("../Images/Products/Hoodie/RRPIC/main-hoody.png"),
			require("../Images/Products/Hoodie/RRPIC/7.png"),
			require("../Images/Products/Hoodie/RRPIC/5.png"),
			require("../Images/Products/Hoodie/RRPIC/4.png"),
			require("../Images/Products/Hoodie/RRPIC/6.png"),
			require("../Images/Products/Hoodie/RRPIC/3.png"),
		],
		button: "Enquire",
	},
	{
		id: 130,
		name: " Men Printed Kangaroo Hoodie 25",
		price: "Price : $9.4",
		category: "Hoodies",
		productionLeadTime: "Production Lead Time: 40 Days",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar Type: Hooded",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Technics: "Technics: Printed",
		fabricType: "Fabric Type: Fleec",
		Material: "Material: Cotton, Polyester",
		Customised: "Customized? YES",
		description:
			"Our hoodie is a top-rated favorite for its comfort, quality, and style. Made from soft, durable materials, it offers warmth and a great fit. The adjustable hood and roomy pocket enhance its practicality, making it a go-to for any casual occasion. Customers love how it stays cozy and stylish, wash after wash. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			soft: "High-Quality Fabric for ultimate comfort.",
			hood: "Adjustable Hood for a personalized fit.",
			pocket: "Convenient Front Pocket for essentials.",
			durable: "Durable Construction that lasts.",
			design: "Versatile Design ; for any casual look.",
			fit: "Comfortable Fit ; for all-day wear.",
			Breathable: " Breathable Material to keep you cozy without overheating.",
			customDesign:
				"Customizable Options to add your unique touch, including colors, prints, and embroidery.",
		},
		imageUrl: [
			require("../Images/Products/Hoodie/RRRPIC/main-hoody.png"),
			require("../Images/Products/Hoodie/RRRPIC/7.png"),
			require("../Images/Products/Hoodie/RRRPIC/5.png"),
			require("../Images/Products/Hoodie/RRRPIC/4.png"),
			require("../Images/Products/Hoodie/RRRPIC/2.png"),
			require("../Images/Products/Hoodie/RRRPIC/3.png"),
		],
		button: "Enquire",
	},
	{
		id: 131,
		name: " Men Printed Kangaroo Hoodie 26",
		price: "Price : $9.4",
		category: "Hoodies",
		productionLeadTime: "Production Lead Time: 40 Days",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar Type: Hooded",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Technics: "Technics: Printed",
		fabricType: "Fabric Type: Fleec",
		Material: "Material: Cotton, Polyester",
		Customised: "Customized? YES",
		description:
			"Our hoodie is a top-rated favorite for its comfort, quality, and style. Made from soft, durable materials, it offers warmth and a great fit. The adjustable hood and roomy pocket enhance its practicality, making it a go-to for any casual occasion. Customers love how it stays cozy and stylish, wash after wash. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			soft: "High-Quality Fabric for ultimate comfort.",
			hood: "Adjustable Hood for a personalized fit.",
			pocket: "Convenient Front Pocket for essentials.",
			durable: "Durable Construction that lasts.",
			design: "Versatile Design ; for any casual look.",
			fit: "Comfortable Fit ; for all-day wear.",
			Breathable: " Breathable Material to keep you cozy without overheating.",
			customDesign:
				"Customizable Options to add your unique touch, including colors, prints, and embroidery.",
		},
		imageUrl: [
			require("../Images/Products/Hoodie/SSPIC/main-hoody.png"),
			require("../Images/Products/Hoodie/SSPIC/6.png"),
			require("../Images/Products/Hoodie/SSPIC/5.png"),
			require("../Images/Products/Hoodie/SSPIC/4.png"),
			require("../Images/Products/Hoodie/SSPIC/2.png"),
			require("../Images/Products/Hoodie/SSPIC/3.png"),
		],
		button: "Enquire",
	},
	{
		id: 132,
		name: " Men Printed Kangaroo Hoodie 27",
		price: "Price : $9.4",
		category: "Hoodies",
		productionLeadTime: "Production Lead Time: 40 Days",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar Type: Hooded",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Technics: "Technics: Printed",
		fabricType: "Fabric Type: Fleec",
		Material: "Material: Cotton, Polyester",
		Customised: "Customized? YES",
		description:
			"Our hoodie is a top-rated favorite for its comfort, quality, and style. Made from soft, durable materials, it offers warmth and a great fit. The adjustable hood and roomy pocket enhance its practicality, making it a go-to for any casual occasion. Customers love how it stays cozy and stylish, wash after wash. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			soft: "High-Quality Fabric for ultimate comfort.",
			hood: "Adjustable Hood for a personalized fit.",
			pocket: "Convenient Front Pocket for essentials.",
			durable: "Durable Construction that lasts.",
			design: "Versatile Design ; for any casual look.",
			fit: "Comfortable Fit ; for all-day wear.",
			Breathable: " Breathable Material to keep you cozy without overheating.",
			customDesign:
				"Customizable Options to add your unique touch, including colors, prints, and embroidery.",
		},
		imageUrl: [
			require("../Images/Products/Hoodie/UPIC/main-hoody.png"),
			require("../Images/Products/Hoodie/UPIC/6.png"),
			require("../Images/Products/Hoodie/UPIC/5.png"),
			require("../Images/Products/Hoodie/UPIC/7.png"),
			require("../Images/Products/Hoodie/UPIC/2.png"),
			require("../Images/Products/Hoodie/UPIC/3.png"),
		],
		button: "Enquire",
	},
	{
		id: 133,
		name: " Men Printed Kangaroo Hoodie 28",
		price: "Price : $9.4",
		category: "Hoodies",
		productionLeadTime: "Production Lead Time: 40 Days",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar Type: Hooded",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Technics: "Technics: Printed",
		fabricType: "Fabric Type: Fleec",
		Material: "Material: Cotton, Polyester",
		Customised: "Customized? YES",
		description:
			"Our hoodie is a top-rated favorite for its comfort, quality, and style. Made from soft, durable materials, it offers warmth and a great fit. The adjustable hood and roomy pocket enhance its practicality, making it a go-to for any casual occasion. Customers love how it stays cozy and stylish, wash after wash. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			soft: "High-Quality Fabric for ultimate comfort.",
			hood: "Adjustable Hood for a personalized fit.",
			pocket: "Convenient Front Pocket for essentials.",
			durable: "Durable Construction that lasts.",
			design: "Versatile Design ; for any casual look.",
			fit: "Comfortable Fit ; for all-day wear.",
			Breathable: " Breathable Material to keep you cozy without overheating.",
			customDesign:
				"Customizable Options to add your unique touch, including colors, prints, and embroidery.",
		},
		imageUrl: [
			require("../Images/Products/Hoodie/WWPIC/main-hoody.png"),
			require("../Images/Products/Hoodie/WWPIC/6.png"),
			require("../Images/Products/Hoodie/WWPIC/5.png"),
			require("../Images/Products/Hoodie/WWPIC/7.png"),
			require("../Images/Products/Hoodie/WWPIC/2.png"),
			require("../Images/Products/Hoodie/WWPIC/4.png"),
		],
		button: "Enquire",
	},
	{
		id: 134,
		name: " Men Printed Kangaroo Hoodie 29",
		price: "Price : $9.4",
		category: "Hoodies",
		productionLeadTime: "Production Lead Time: 40 Days",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar Type: Hooded",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Technics: "Technics: Printed",
		fabricType: "Fabric Type: Fleec",
		Material: "Material: Cotton, Polyester",
		Customised: "Customized? YES",
		description:
			"Our hoodie is a top-rated favorite for its comfort, quality, and style. Made from soft, durable materials, it offers warmth and a great fit. The adjustable hood and roomy pocket enhance its practicality, making it a go-to for any casual occasion. Customers love how it stays cozy and stylish, wash after wash. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			soft: "High-Quality Fabric for ultimate comfort.",
			hood: "Adjustable Hood for a personalized fit.",
			pocket: "Convenient Front Pocket for essentials.",
			durable: "Durable Construction that lasts.",
			design: "Versatile Design ; for any casual look.",
			fit: "Comfortable Fit ; for all-day wear.",
			Breathable: " Breathable Material to keep you cozy without overheating.",
			customDesign:
				"Customizable Options to add your unique touch, including colors, prints, and embroidery.",
		},
		imageUrl: [
			require("../Images/Products/Hoodie/XXPIC/main-hoody.png"),
			require("../Images/Products/Hoodie/XXPIC/6.png"),
			require("../Images/Products/Hoodie/XXPIC/3.png"),
			require("../Images/Products/Hoodie/XXPIC/7.png"),
			require("../Images/Products/Hoodie/XXPIC/2.png"),
			require("../Images/Products/Hoodie/XXPIC/4.png"),
		],
		button: "Enquire",
	},
	{
		id: 135,
		name: " Men Printed Kangaroo Hoodie 30",
		price: "Price : $9.4",
		category: "Hoodies",
		productionLeadTime: "Production Lead Time: 40 Days",
		Sizes: "Available Size: S, M, L, XL, XXL, XXXL",
		Collar: "Collar Type: Hooded",
		minimunOrderQuantity: "Menimum Order Quantity: 200",
		Technics: "Technics: Printed",
		fabricType: "Fabric Type: Fleec",
		Material: "Material: Cotton, Polyester",
		Customised: "Customized? YES",
		description:
			"Our hoodie is a top-rated favorite for its comfort, quality, and style. Made from soft, durable materials, it offers warmth and a great fit. The adjustable hood and roomy pocket enhance its practicality, making it a go-to for any casual occasion. Customers love how it stays cozy and stylish, wash after wash. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			soft: "High-Quality Fabric for ultimate comfort.",
			hood: "Adjustable Hood for a personalized fit.",
			pocket: "Convenient Front Pocket for essentials.",
			durable: "Durable Construction that lasts.",
			design: "Versatile Design ; for any casual look.",
			fit: "Comfortable Fit ; for all-day wear.",
			Breathable: " Breathable Material to keep you cozy without overheating.",
			customDesign:
				"Customizable Options to add your unique touch, including colors, prints, and embroidery.",
		},
		imageUrl: [
			require("../Images/Products/Hoodie/ZZPIC/main-hoody.png"),
			require("../Images/Products/Hoodie/ZZPIC/6.png"),
			require("../Images/Products/Hoodie/ZZPIC/3.png"),
			require("../Images/Products/Hoodie/ZZPIC/7.png"),
			require("../Images/Products/Hoodie/ZZPIC/2.png"),
			require("../Images/Products/Hoodie/ZZPIC/5.png"),
		],
		button: "Enquire",
	},

	// ================================================= Sweat Shirts

	{
		id: 136,
		name: "Sweat Shirt 1",
		price: "Price : $39.99",
		category: "Sweat Shirt",
		productionLeadTime: "Production Lead Time: ABC",
		Sizes: "Available Size: S, M, L, XL, XXXL",
		Collar: "Collar Type: NA",
		minimunOrderQuantity: "Menimum Order Quantity: NA",
		Technics: "Technics: ABC,EFG,XYZ",
		Season: "NA",
		fabricType: "Fabric Type: NA",
		Material: "Material: NA",
		Customised: "Customized? YES",
		description:
			"Embrace timeless style and everyday comfort with our premium sweatshirt, thoughtfully designed for versatility and performance. Crafted from a superior blend of soft cotton and durable polyester, this sweatshirt offers the ideal combination of warmth and breathability, making it perfect for any occasion—from lounging at home to braving chilly outdoor adventures. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "Soft, durable cotton-polyester blend",
			customDesign: "Relaxed, ergonomic fit for comfort and mobility",
			vibrantPrints: "Soft, High-Quality Fabric for ultimate comfort.",
			unisexFit: "Add your own text, logos, or artwork.",
			multipleColors: "High-quality, durable prints that won’t crack or fade.",
			reinforcedStitching: " Comfortable fit for all body types",
			ecoFriendlyPrinting: " Choose from a variety of color options to match your style.",
			lightweightAndStretchable: "Durable stitching for long-lasting wear.",
			fit: "Uses eco-conscious ink and processes.",
			customDesign:
				"Customizable Options to add your unique touch, including colors, prints, and embroidery.",
		},
		imageUrl: [
			require("../Images/Products/Sweat shirt/AAAPIC1/main-shirt.png"),
			require("../Images/Products/Sweat shirt/AAAPIC1/1.png"),
			require("../Images/Products/Sweat shirt/AAAPIC1/2.png"),
			require("../Images/Products/Sweat shirt/AAAPIC1/3.png"),
			require("../Images/Products/Sweat shirt/AAAPIC1/4.png"),
			require("../Images/Products/Sweat shirt/AAAPIC1/5.png"),
		],
		button: "Enquire",
	},
	{
		id: 137,
		name: "Sweat Shirt 2",
		price: "Price : $39.99",
		category: "Sweat Shirt",
		productionLeadTime: "Production Lead Time: ABC",
		Sizes: "Available Size: S, M, L, XL, XXXL",
		Collar: "Collar Type: NA",
		minimunOrderQuantity: "Menimum Order Quantity: NA",
		Technics: "Technics: ABC,EFG,XYZ",
		Season: "NA",
		fabricType: "Fabric Type: NA",
		Material: "Material: NA",
		Customised: "Customized? YES",
		description:
			"Embrace timeless style and everyday comfort with our premium sweatshirt, thoughtfully designed for versatility and performance. Crafted from a superior blend of soft cotton and durable polyester, this sweatshirt offers the ideal combination of warmth and breathability, making it perfect for any occasion—from lounging at home to braving chilly outdoor adventures. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "Soft, durable cotton-polyester blend",
			customDesign: "Relaxed, ergonomic fit for comfort and mobility",
			vibrantPrints: "Soft, High-Quality Fabric for ultimate comfort.",
			unisexFit: "Add your own text, logos, or artwork.",
			multipleColors: "High-quality, durable prints that won’t crack or fade.",
			reinforcedStitching: " Comfortable fit for all body types",
			ecoFriendlyPrinting: " Choose from a variety of color options to match your style.",
			lightweightAndStretchable: "Durable stitching for long-lasting wear.",
			fit: "Uses eco-conscious ink and processes.",
			customDesign:
				"Customizable Options to add your unique touch, including colors, prints, and embroidery.",
		},
		imageUrl: [
			require("../Images/Products/Sweat shirt/AAPIC2/main-shirt.png"),
			require("../Images/Products/Sweat shirt/AAPIC2/1.png"),
			require("../Images/Products/Sweat shirt/AAPIC2/2.png"),
			require("../Images/Products/Sweat shirt/AAPIC2/4.png"),
			require("../Images/Products/Sweat shirt/AAPIC2/5.png"),
			require("../Images/Products/Sweat shirt/AAPIC2/7.png"),
		],
		button: "Enquire",
	},
	{
		id: 138,
		name: "Sweat Shirt 3",
		price: "Price : $39.99",
		category: "Sweat Shirt",
		productionLeadTime: "Production Lead Time: ABC",
		Sizes: "Available Size: S, M, L, XL, XXXL",
		Collar: "Collar Type: NA",
		minimunOrderQuantity: "Menimum Order Quantity: NA",
		Technics: "Technics: ABC,EFG,XYZ",
		Season: "NA",
		fabricType: "Fabric Type: NA",
		Material: "Material: NA",
		Customised: "Customized? YES",
		description:
			"Embrace timeless style and everyday comfort with our premium sweatshirt, thoughtfully designed for versatility and performance. Crafted from a superior blend of soft cotton and durable polyester, this sweatshirt offers the ideal combination of warmth and breathability, making it perfect for any occasion—from lounging at home to braving chilly outdoor adventures. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "Soft, durable cotton-polyester blend",
			customDesign: "Relaxed, ergonomic fit for comfort and mobility",
			vibrantPrints: "Soft, High-Quality Fabric for ultimate comfort.",
			unisexFit: "Add your own text, logos, or artwork.",
			multipleColors: "High-quality, durable prints that won’t crack or fade.",
			reinforcedStitching: " Comfortable fit for all body types",
			ecoFriendlyPrinting: " Choose from a variety of color options to match your style.",
			lightweightAndStretchable: "Durable stitching for long-lasting wear.",
			fit: "Uses eco-conscious ink and processes.",
			customDesign:
				"Customizable Options to add your unique touch, including colors, prints, and embroidery.",
		},
		imageUrl: [
			require("../Images/Products/Sweat shirt/CPIC3/main-shirt.png"),
			require("../Images/Products/Sweat shirt/CPIC3/1.png"),
			require("../Images/Products/Sweat shirt/CPIC3/2.png"),
			require("../Images/Products/Sweat shirt/CPIC3/3.png"),
			require("../Images/Products/Sweat shirt/CPIC3/4.png"),
			require("../Images/Products/Sweat shirt/CPIC3/5.png"),
		],
		button: "Enquire",
	},
	{
		id: 139,
		name: "Sweat Shirt 4",
		price: "Price : $39.99",
		category: "Sweat Shirt",
		productionLeadTime: "Production Lead Time: ABC",
		Sizes: "Available Size: S, M, L, XL, XXXL",
		Collar: "Collar Type: NA",
		minimunOrderQuantity: "Menimum Order Quantity: NA",
		Technics: "Technics: ABC,EFG,XYZ",
		Season: "NA",
		fabricType: "Fabric Type: NA",
		Material: "Material: NA",
		Customised: "Customized? YES",
		description:
			"Embrace timeless style and everyday comfort with our premium sweatshirt, thoughtfully designed for versatility and performance. Crafted from a superior blend of soft cotton and durable polyester, this sweatshirt offers the ideal combination of warmth and breathability, making it perfect for any occasion—from lounging at home to braving chilly outdoor adventures. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "Soft, durable cotton-polyester blend",
			customDesign: "Relaxed, ergonomic fit for comfort and mobility",
			vibrantPrints: "Soft, High-Quality Fabric for ultimate comfort.",
			unisexFit: "Add your own text, logos, or artwork.",
			multipleColors: "High-quality, durable prints that won’t crack or fade.",
			reinforcedStitching: " Comfortable fit for all body types",
			ecoFriendlyPrinting: " Choose from a variety of color options to match your style.",
			lightweightAndStretchable: "Durable stitching for long-lasting wear.",
			fit: "Uses eco-conscious ink and processes.",
			customDesign:
				"Customizable Options to add your unique touch, including colors, prints, and embroidery.",
		},
		imageUrl: [
			require("../Images/Products/Sweat shirt/DDDPIC4/main-shirt.png"),
			require("../Images/Products/Sweat shirt/DDDPIC4/1.png"),
			require("../Images/Products/Sweat shirt/DDDPIC4/6.png"),
			require("../Images/Products/Sweat shirt/DDDPIC4/3.png"),
			require("../Images/Products/Sweat shirt/DDDPIC4/4.png"),
			require("../Images/Products/Sweat shirt/DDDPIC4/5.png"),
		],
		button: "Enquire",
	},
	{
		id: 140,
		name: "Sweat Shirt 5",
		price: "Price : $39.99",
		category: "Sweat Shirt",
		productionLeadTime: "Production Lead Time: ABC",
		Sizes: "Available Size: S, M, L, XL, XXXL",
		Collar: "Collar Type: NA",
		minimunOrderQuantity: "Menimum Order Quantity: NA",
		Technics: "Technics: ABC,EFG,XYZ",
		Season: "NA",
		fabricType: "Fabric Type: NA",
		Material: "Material: NA",
		Customised: "Customized? YES",
		description:
			"Embrace timeless style and everyday comfort with our premium sweatshirt, thoughtfully designed for versatility and performance. Crafted from a superior blend of soft cotton and durable polyester, this sweatshirt offers the ideal combination of warmth and breathability, making it perfect for any occasion—from lounging at home to braving chilly outdoor adventures. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "Soft, durable cotton-polyester blend",
			customDesign: "Relaxed, ergonomic fit for comfort and mobility",
			vibrantPrints: "Soft, High-Quality Fabric for ultimate comfort.",
			unisexFit: "Add your own text, logos, or artwork.",
			multipleColors: "High-quality, durable prints that won’t crack or fade.",
			reinforcedStitching: " Comfortable fit for all body types",
			ecoFriendlyPrinting: " Choose from a variety of color options to match your style.",
			lightweightAndStretchable: "Durable stitching for long-lasting wear.",
			fit: "Uses eco-conscious ink and processes.",
			customDesign:
				"Customizable Options to add your unique touch, including colors, prints, and embroidery.",
		},
		imageUrl: [
			require("../Images/Products/Sweat shirt/FFFPIC5/main-shirt.png"),
			require("../Images/Products/Sweat shirt/FFFPIC5/1.png"),
			require("../Images/Products/Sweat shirt/FFFPIC5/6.png"),
			require("../Images/Products/Sweat shirt/FFFPIC5/3.png"),
			require("../Images/Products/Sweat shirt/FFFPIC5/4.png"),
			require("../Images/Products/Sweat shirt/FFFPIC5/5.png"),
		],
		button: "Enquire",
	},
	{
		id: 141,
		name: "Sweat Shirt 6",
		price: "Price : $39.99",
		category: "Sweat Shirt",
		productionLeadTime: "Production Lead Time: ABC",
		Sizes: "Available Size: S, M, L, XL, XXXL",
		Collar: "Collar Type: NA",
		minimunOrderQuantity: "Menimum Order Quantity: NA",
		Technics: "Technics: ABC,EFG,XYZ",
		Season: "NA",
		fabricType: "Fabric Type: NA",
		Material: "Material: NA",
		Customised: "Customized? YES",
		description:
			"Embrace timeless style and everyday comfort with our premium sweatshirt, thoughtfully designed for versatility and performance. Crafted from a superior blend of soft cotton and durable polyester, this sweatshirt offers the ideal combination of warmth and breathability, making it perfect for any occasion—from lounging at home to braving chilly outdoor adventures. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "Soft, durable cotton-polyester blend",
			customDesign: "Relaxed, ergonomic fit for comfort and mobility",
			vibrantPrints: "Soft, High-Quality Fabric for ultimate comfort.",
			unisexFit: "Add your own text, logos, or artwork.",
			multipleColors: "High-quality, durable prints that won’t crack or fade.",
			reinforcedStitching: " Comfortable fit for all body types",
			ecoFriendlyPrinting: " Choose from a variety of color options to match your style.",
			lightweightAndStretchable: "Durable stitching for long-lasting wear.",
			fit: "Uses eco-conscious ink and processes.",
			customDesign:
				"Customizable Options to add your unique touch, including colors, prints, and embroidery.",
		},
		imageUrl: [
			require("../Images/Products/Sweat shirt/FPIC6/main-shirt.png"),
			require("../Images/Products/Sweat shirt/FPIC6/19.png"),
			require("../Images/Products/Sweat shirt/FPIC6/6.png"),
			require("../Images/Products/Sweat shirt/FPIC6/8.png"),
			require("../Images/Products/Sweat shirt/FPIC6/17.png"),
			require("../Images/Products/Sweat shirt/FPIC6/20.png"),
		],
		button: "Enquire",
	},
	{
		id: 142,
		name: "Sweat Shirt 7",
		price: "Price : $39.99",
		category: "Sweat Shirt",
		productionLeadTime: "Production Lead Time: ABC",
		Sizes: "Available Size: S, M, L, XL, XXXL",
		Collar: "Collar Type: NA",
		minimunOrderQuantity: "Menimum Order Quantity: NA",
		Technics: "Technics: ABC,EFG,XYZ",
		Season: "NA",
		fabricType: "Fabric Type: NA",
		Material: "Material: NA",
		Customised: "Customized? YES",
		description:
			"Embrace timeless style and everyday comfort with our premium sweatshirt, thoughtfully designed for versatility and performance. Crafted from a superior blend of soft cotton and durable polyester, this sweatshirt offers the ideal combination of warmth and breathability, making it perfect for any occasion—from lounging at home to braving chilly outdoor adventures. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "Soft, durable cotton-polyester blend",
			customDesign: "Relaxed, ergonomic fit for comfort and mobility",
			vibrantPrints: "Soft, High-Quality Fabric for ultimate comfort.",
			unisexFit: "Add your own text, logos, or artwork.",
			multipleColors: "High-quality, durable prints that won’t crack or fade.",
			reinforcedStitching: " Comfortable fit for all body types",
			ecoFriendlyPrinting: " Choose from a variety of color options to match your style.",
			lightweightAndStretchable: "Durable stitching for long-lasting wear.",
			fit: "Uses eco-conscious ink and processes.",
			customDesign:
				"Customizable Options to add your unique touch, including colors, prints, and embroidery.",
		},
		imageUrl: [
			require("../Images/Products/Sweat shirt/GGGPIC7/main-shirt.png"),
			require("../Images/Products/Sweat shirt/GGGPIC7/7.png"),
			require("../Images/Products/Sweat shirt/GGGPIC7/6.png"),
			require("../Images/Products/Sweat shirt/GGGPIC7/8.png"),
			require("../Images/Products/Sweat shirt/GGGPIC7/22.png"),
			require("../Images/Products/Sweat shirt/GGGPIC7/23.png"),
			require("../Images/Products/Sweat shirt/GGGPIC7/24.png"),
		],
		button: "Enquire",
	},
	{
		id: 143,
		name: "Sweat Shirt 8",
		price: "Price : $39.99",
		category: "Sweat Shirt",
		productionLeadTime: "Production Lead Time: ABC",
		Sizes: "Available Size: S, M, L, XL, XXXL",
		Collar: "Collar Type: NA",
		minimunOrderQuantity: "Menimum Order Quantity: NA",
		Technics: "Technics: ABC,EFG,XYZ",
		Season: "NA",
		fabricType: "Fabric Type: NA",
		Material: "Material: NA",
		Customised: "Customized? YES",
		description:
			"Embrace timeless style and everyday comfort with our premium sweatshirt, thoughtfully designed for versatility and performance. Crafted from a superior blend of soft cotton and durable polyester, this sweatshirt offers the ideal combination of warmth and breathability, making it perfect for any occasion—from lounging at home to braving chilly outdoor adventures. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "Soft, durable cotton-polyester blend",
			customDesign: "Relaxed, ergonomic fit for comfort and mobility",
			vibrantPrints: "Soft, High-Quality Fabric for ultimate comfort.",
			unisexFit: "Add your own text, logos, or artwork.",
			multipleColors: "High-quality, durable prints that won’t crack or fade.",
			reinforcedStitching: " Comfortable fit for all body types",
			ecoFriendlyPrinting: " Choose from a variety of color options to match your style.",
			lightweightAndStretchable: "Durable stitching for long-lasting wear.",
			fit: "Uses eco-conscious ink and processes.",
			customDesign:
				"Customizable Options to add your unique touch, including colors, prints, and embroidery.",
		},
		imageUrl: [
			require("../Images/Products/Sweat shirt/GPIC8/main-shirt.png"),
			require("../Images/Products/Sweat shirt/GPIC8/25.png"),
			require("../Images/Products/Sweat shirt/GPIC8/6.png"),
			require("../Images/Products/Sweat shirt/GPIC8/8.png"),
			require("../Images/Products/Sweat shirt/GPIC8/26.png"),
			require("../Images/Products/Sweat shirt/GPIC8/27.png"),
		],
		button: "Enquire",
	},
	{
		id: 144,
		name: "Sweat Shirt 9",
		price: "Price : $39.99",
		category: "Sweat Shirt",
		productionLeadTime: "Production Lead Time: ABC",
		Sizes: "Available Size: S, M, L, XL, XXXL",
		Collar: "Collar Type: NA",
		minimunOrderQuantity: "Menimum Order Quantity: NA",
		Technics: "Technics: ABC,EFG,XYZ",
		Season: "NA",
		fabricType: "Fabric Type: NA",
		Material: "Material: NA",
		Customised: "Customized? YES",
		description:
			"Embrace timeless style and everyday comfort with our premium sweatshirt, thoughtfully designed for versatility and performance. Crafted from a superior blend of soft cotton and durable polyester, this sweatshirt offers the ideal combination of warmth and breathability, making it perfect for any occasion—from lounging at home to braving chilly outdoor adventures. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "Soft, durable cotton-polyester blend",
			customDesign: "Relaxed, ergonomic fit for comfort and mobility",
			vibrantPrints: "Soft, High-Quality Fabric for ultimate comfort.",
			unisexFit: "Add your own text, logos, or artwork.",
			multipleColors: "High-quality, durable prints that won’t crack or fade.",
			reinforcedStitching: " Comfortable fit for all body types",
			ecoFriendlyPrinting: " Choose from a variety of color options to match your style.",
			lightweightAndStretchable: "Durable stitching for long-lasting wear.",
			fit: "Uses eco-conscious ink and processes.",
			customDesign:
				"Customizable Options to add your unique touch, including colors, prints, and embroidery.",
		},
		imageUrl: [
			require("../Images/Products/Sweat shirt/HPIC9/main-shirt.png"),
			require("../Images/Products/Sweat shirt/HPIC9/29.png"),
			require("../Images/Products/Sweat shirt/HPIC9/6.png"),
			require("../Images/Products/Sweat shirt/HPIC9/30.png"),
			require("../Images/Products/Sweat shirt/HPIC9/31.png"),
			require("../Images/Products/Sweat shirt/HPIC9/32.png"),
		],
		button: "Enquire",
	},
	{
		id: 145,
		name: "Sweat Shirt 10",
		price: "Price : $39.99",
		category: "Sweat Shirt",
		productionLeadTime: "Production Lead Time: ABC",
		Sizes: "Available Size: S, M, L, XL, XXXL",
		Collar: "Collar Type: NA",
		minimunOrderQuantity: "Menimum Order Quantity: NA",
		Technics: "Technics: ABC,EFG,XYZ",
		Season: "NA",
		fabricType: "Fabric Type: NA",
		Material: "Material: NA",
		Customised: "Customized? YES",
		description:
			"Embrace timeless style and everyday comfort with our premium sweatshirt, thoughtfully designed for versatility and performance. Crafted from a superior blend of soft cotton and durable polyester, this sweatshirt offers the ideal combination of warmth and breathability, making it perfect for any occasion—from lounging at home to braving chilly outdoor adventures. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "Soft, durable cotton-polyester blend",
			customDesign: "Relaxed, ergonomic fit for comfort and mobility",
			vibrantPrints: "Soft, High-Quality Fabric for ultimate comfort.",
			unisexFit: "Add your own text, logos, or artwork.",
			multipleColors: "High-quality, durable prints that won’t crack or fade.",
			reinforcedStitching: " Comfortable fit for all body types",
			ecoFriendlyPrinting: " Choose from a variety of color options to match your style.",
			lightweightAndStretchable: "Durable stitching for long-lasting wear.",
			fit: "Uses eco-conscious ink and processes.",
			customDesign:
				"Customizable Options to add your unique touch, including colors, prints, and embroidery.",
		},
		imageUrl: [
			require("../Images/Products/Sweat shirt/IPIC10/main-shirt.png"),
			require("../Images/Products/Sweat shirt/IPIC10/8.png"),
			require("../Images/Products/Sweat shirt/IPIC10/6.png"),
			require("../Images/Products/Sweat shirt/IPIC10/34.png"),
			require("../Images/Products/Sweat shirt/IPIC10/33.png"),
			require("../Images/Products/Sweat shirt/IPIC10/36.png"),
		],
		button: "Enquire",
	},
	{
		id: 146,
		name: "Sweat Shirt 11",
		price: "Price : $39.99",
		category: "Sweat Shirt",
		productionLeadTime: "Production Lead Time: ABC",
		Sizes: "Available Size: S, M, L, XL, XXXL",
		Collar: "Collar Type: NA",
		minimunOrderQuantity: "Menimum Order Quantity: NA",
		Technics: "Technics: ABC,EFG,XYZ",
		Season: "NA",
		fabricType: "Fabric Type: NA",
		Material: "Material: NA",
		Customised: "Customized? YES",
		description:
			"Embrace timeless style and everyday comfort with our premium sweatshirt, thoughtfully designed for versatility and performance. Crafted from a superior blend of soft cotton and durable polyester, this sweatshirt offers the ideal combination of warmth and breathability, making it perfect for any occasion—from lounging at home to braving chilly outdoor adventures. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "Soft, durable cotton-polyester blend",
			customDesign: "Relaxed, ergonomic fit for comfort and mobility",
			vibrantPrints: "Soft, High-Quality Fabric for ultimate comfort.",
			unisexFit: "Add your own text, logos, or artwork.",
			multipleColors: "High-quality, durable prints that won’t crack or fade.",
			reinforcedStitching: " Comfortable fit for all body types",
			ecoFriendlyPrinting: " Choose from a variety of color options to match your style.",
			lightweightAndStretchable: "Durable stitching for long-lasting wear.",
			fit: "Uses eco-conscious ink and processes.",
			customDesign:
				"Customizable Options to add your unique touch, including colors, prints, and embroidery.",
		},
		imageUrl: [
			require("../Images/Products/Sweat shirt/KKPIC11/main-shirt.png"),
			require("../Images/Products/Sweat shirt/KKPIC11/7.png"),
			require("../Images/Products/Sweat shirt/KKPIC11/37.png"),
			require("../Images/Products/Sweat shirt/KKPIC11/38.png"),
			require("../Images/Products/Sweat shirt/KKPIC11/39.png"),
			require("../Images/Products/Sweat shirt/KKPIC11/40.png"),
		],
		button: "Enquire",
	},
	{
		id: 147,
		name: "Sweat Shirt 12",
		price: "Price : $39.99",
		category: "Sweat Shirt",
		productionLeadTime: "Production Lead Time: ABC",
		Sizes: "Available Size: S, M, L, XL, XXXL",
		Collar: "Collar Type: NA",
		minimunOrderQuantity: "Menimum Order Quantity: NA",
		Technics: "Technics: ABC,EFG,XYZ",
		Season: "NA",
		fabricType: "Fabric Type: NA",
		Material: "Material: NA",
		Customised: "Customized? YES",
		description:
			"Embrace timeless style and everyday comfort with our premium sweatshirt, thoughtfully designed for versatility and performance. Crafted from a superior blend of soft cotton and durable polyester, this sweatshirt offers the ideal combination of warmth and breathability, making it perfect for any occasion—from lounging at home to braving chilly outdoor adventures. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "Soft, durable cotton-polyester blend",
			customDesign: "Relaxed, ergonomic fit for comfort and mobility",
			vibrantPrints: "Soft, High-Quality Fabric for ultimate comfort.",
			unisexFit: "Add your own text, logos, or artwork.",
			multipleColors: "High-quality, durable prints that won’t crack or fade.",
			reinforcedStitching: " Comfortable fit for all body types",
			ecoFriendlyPrinting: " Choose from a variety of color options to match your style.",
			lightweightAndStretchable: "Durable stitching for long-lasting wear.",
			fit: "Uses eco-conscious ink and processes.",
			customDesign:
				"Customizable Options to add your unique touch, including colors, prints, and embroidery.",
		},
		imageUrl: [
			require("../Images/Products/Sweat shirt/LLPIC12/main-shirt.png"),
			require("../Images/Products/Sweat shirt/LLPIC12/6.png"),
			require("../Images/Products/Sweat shirt/LLPIC12/41.png"),
			require("../Images/Products/Sweat shirt/LLPIC12/42.png"),
			require("../Images/Products/Sweat shirt/LLPIC12/43.png"),
			require("../Images/Products/Sweat shirt/LLPIC12/44.png"),
		],
		button: "Enquire",
	},
	{
		id: 148,
		name: "Sweat Shirt 13",
		price: "Price : $39.99",
		category: "Sweat Shirt",
		productionLeadTime: "Production Lead Time: ABC",
		Sizes: "Available Size: S, M, L, XL, XXXL",
		Collar: "Collar Type: NA",
		minimunOrderQuantity: "Menimum Order Quantity: NA",
		Technics: "Technics: ABC,EFG,XYZ",
		Season: "NA",
		fabricType: "Fabric Type: NA",
		Material: "Material: NA",
		Customised: "Customized? YES",
		description:
			"Embrace timeless style and everyday comfort with our premium sweatshirt, thoughtfully designed for versatility and performance. Crafted from a superior blend of soft cotton and durable polyester, this sweatshirt offers the ideal combination of warmth and breathability, making it perfect for any occasion—from lounging at home to braving chilly outdoor adventures. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "Soft, durable cotton-polyester blend",
			customDesign: "Relaxed, ergonomic fit for comfort and mobility",
			vibrantPrints: "Soft, High-Quality Fabric for ultimate comfort.",
			unisexFit: "Add your own text, logos, or artwork.",
			multipleColors: "High-quality, durable prints that won’t crack or fade.",
			reinforcedStitching: " Comfortable fit for all body types",
			ecoFriendlyPrinting: " Choose from a variety of color options to match your style.",
			lightweightAndStretchable: "Durable stitching for long-lasting wear.",
			fit: "Uses eco-conscious ink and processes.",
			customDesign:
				"Customizable Options to add your unique touch, including colors, prints, and embroidery.",
		},
		imageUrl: [
			require("../Images/Products/Sweat shirt/LPIC13/main-shirt.png"),
			require("../Images/Products/Sweat shirt/LPIC13/1.png"),
			require("../Images/Products/Sweat shirt/LPIC13/2.png"),
			require("../Images/Products/Sweat shirt/LPIC13/6.png"),
			require("../Images/Products/Sweat shirt/LPIC13/8.png"),
			require("../Images/Products/Sweat shirt/LPIC13/4.png"),
		],
		button: "Enquire",
	},
	{
		id: 149,
		name: "Sweat Shirt 14",
		price: "Price : $39.99",
		category: "Sweat Shirt",
		productionLeadTime: "Production Lead Time: ABC",
		Sizes: "Available Size: S, M, L, XL, XXXL",
		Collar: "Collar Type: NA",
		minimunOrderQuantity: "Menimum Order Quantity: NA",
		Technics: "Technics: ABC,EFG,XYZ",
		Season: "NA",
		fabricType: "Fabric Type: NA",
		Material: "Material: NA",
		Customised: "Customized? YES",
		description:
			"Embrace timeless style and everyday comfort with our premium sweatshirt, thoughtfully designed for versatility and performance. Crafted from a superior blend of soft cotton and durable polyester, this sweatshirt offers the ideal combination of warmth and breathability, making it perfect for any occasion—from lounging at home to braving chilly outdoor adventures. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "Soft, durable cotton-polyester blend",
			customDesign: "Relaxed, ergonomic fit for comfort and mobility",
			vibrantPrints: "Soft, High-Quality Fabric for ultimate comfort.",
			unisexFit: "Add your own text, logos, or artwork.",
			multipleColors: "High-quality, durable prints that won’t crack or fade.",
			reinforcedStitching: " Comfortable fit for all body types",
			ecoFriendlyPrinting: " Choose from a variety of color options to match your style.",
			lightweightAndStretchable: "Durable stitching for long-lasting wear.",
			fit: "Uses eco-conscious ink and processes.",
			customDesign:
				"Customizable Options to add your unique touch, including colors, prints, and embroidery.",
		},
		imageUrl: [
			require("../Images/Products/Sweat shirt/MPIC14/main-shirt.png"),
			require("../Images/Products/Sweat shirt/MPIC14/6.png"),
			require("../Images/Products/Sweat shirt/MPIC14/8.png"),
			require("../Images/Products/Sweat shirt/MPIC14/66.png"),
			require("../Images/Products/Sweat shirt/MPIC14/77.png"),
			require("../Images/Products/Sweat shirt/MPIC14/88.png"),
		],
		button: "Enquire",
	},
	{
		id: 150,
		name: "Sweat Shirt 15",
		price: "Price : $39.99",
		category: "Sweat Shirt",
		productionLeadTime: "Production Lead Time: ABC",
		Sizes: "Available Size: S, M, L, XL, XXXL",
		Collar: "Collar Type: NA",
		minimunOrderQuantity: "Menimum Order Quantity: NA",
		Technics: "Technics: ABC,EFG,XYZ",
		Season: "NA",
		fabricType: "Fabric Type: NA",
		Material: "Material: NA",
		Customised: "Customized? YES",
		description:
			"Embrace timeless style and everyday comfort with our premium sweatshirt, thoughtfully designed for versatility and performance. Crafted from a superior blend of soft cotton and durable polyester, this sweatshirt offers the ideal combination of warmth and breathability, making it perfect for any occasion—from lounging at home to braving chilly outdoor adventures. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "Soft, durable cotton-polyester blend",
			customDesign: "Relaxed, ergonomic fit for comfort and mobility",
			vibrantPrints: "Soft, High-Quality Fabric for ultimate comfort.",
			unisexFit: "Add your own text, logos, or artwork.",
			multipleColors: "High-quality, durable prints that won’t crack or fade.",
			reinforcedStitching: " Comfortable fit for all body types",
			ecoFriendlyPrinting: " Choose from a variety of color options to match your style.",
			lightweightAndStretchable: "Durable stitching for long-lasting wear.",
			fit: "Uses eco-conscious ink and processes.",
			customDesign:
				"Customizable Options to add your unique touch, including colors, prints, and embroidery.",
		},
		imageUrl: [
			require("../Images/Products/Sweat shirt/NNPIC15/main-shirt.png"),
			require("../Images/Products/Sweat shirt/NNPIC15/9.png"),
			require("../Images/Products/Sweat shirt/NNPIC15/8.png"),
			require("../Images/Products/Sweat shirt/NNPIC15/10.png"),
			require("../Images/Products/Sweat shirt/NNPIC15/11.png"),
			require("../Images/Products/Sweat shirt/NNPIC15/12.png"),
		],
		button: "Enquire",
	},
	{
		id: 151,
		name: "Sweat Shirt 16",
		price: "Price : $39.99",
		category: "Sweat Shirt",
		productionLeadTime: "Production Lead Time: ABC",
		Sizes: "Available Size: S, M, L, XL, XXXL",
		Collar: "Collar Type: NA",
		minimunOrderQuantity: "Menimum Order Quantity: NA",
		Technics: "Technics: ABC,EFG,XYZ",
		Season: "NA",
		fabricType: "Fabric Type: NA",
		Material: "Material: NA",
		Customised: "Customized? YES",
		description:
			"Embrace timeless style and everyday comfort with our premium sweatshirt, thoughtfully designed for versatility and performance. Crafted from a superior blend of soft cotton and durable polyester, this sweatshirt offers the ideal combination of warmth and breathability, making it perfect for any occasion—from lounging at home to braving chilly outdoor adventures. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "Soft, durable cotton-polyester blend",
			customDesign: "Relaxed, ergonomic fit for comfort and mobility",
			vibrantPrints: "Soft, High-Quality Fabric for ultimate comfort.",
			unisexFit: "Add your own text, logos, or artwork.",
			multipleColors: "High-quality, durable prints that won’t crack or fade.",
			reinforcedStitching: " Comfortable fit for all body types",
			ecoFriendlyPrinting: " Choose from a variety of color options to match your style.",
			lightweightAndStretchable: "Durable stitching for long-lasting wear.",
			fit: "Uses eco-conscious ink and processes.",
			customDesign:
				"Customizable Options to add your unique touch, including colors, prints, and embroidery.",
		},
		imageUrl: [
			require("../Images/Products/Sweat shirt/OPIC16/main-shirt.png"),
			require("../Images/Products/Sweat shirt/OPIC16/7.png"),
			require("../Images/Products/Sweat shirt/OPIC16/13.png"),
			require("../Images/Products/Sweat shirt/OPIC16/14.png"),
			require("../Images/Products/Sweat shirt/OPIC16/15.png"),
			require("../Images/Products/Sweat shirt/OPIC16/16.png"),
		],
		button: "Enquire",
	},
	{
		id: 152,
		name: "Sweat Shirt 17",
		price: "Price : $39.99",
		category: "Sweat Shirt",
		productionLeadTime: "Production Lead Time: ABC",
		Sizes: "Available Size: S, M, L, XL, XXXL",
		Collar: "Collar Type: NA",
		minimunOrderQuantity: "Menimum Order Quantity: NA",
		Technics: "Technics: ABC,EFG,XYZ",
		Season: "NA",
		fabricType: "Fabric Type: NA",
		Material: "Material: NA",
		Customised: "Customized? YES",
		description:
			"Embrace timeless style and everyday comfort with our premium sweatshirt, thoughtfully designed for versatility and performance. Crafted from a superior blend of soft cotton and durable polyester, this sweatshirt offers the ideal combination of warmth and breathability, making it perfect for any occasion—from lounging at home to braving chilly outdoor adventures. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "Soft, durable cotton-polyester blend",
			customDesign: "Relaxed, ergonomic fit for comfort and mobility",
			vibrantPrints: "Soft, High-Quality Fabric for ultimate comfort.",
			unisexFit: "Add your own text, logos, or artwork.",
			multipleColors: "High-quality, durable prints that won’t crack or fade.",
			reinforcedStitching: " Comfortable fit for all body types",
			ecoFriendlyPrinting: " Choose from a variety of color options to match your style.",
			lightweightAndStretchable: "Durable stitching for long-lasting wear.",
			fit: "Uses eco-conscious ink and processes.",
			customDesign:
				"Customizable Options to add your unique touch, including colors, prints, and embroidery.",
		},
		imageUrl: [
			require("../Images/Products/Sweat shirt/VVPIC17/main-shirt.png"),
			require("../Images/Products/Sweat shirt/VVPIC17/6.png"),
			require("../Images/Products/Sweat shirt/VVPIC17/8.png"),
			require("../Images/Products/Sweat shirt/VVPIC17/19.png"),
			require("../Images/Products/Sweat shirt/VVPIC17/17.png"),
			require("../Images/Products/Sweat shirt/VVPIC17/20.png"),
		],
		button: "Enquire",
	},
	{
		id: 153,
		name: "Sweat Shirt 18",
		price: "Price : $39.99",
		category: "Sweat Shirt",
		productionLeadTime: "Production Lead Time: ABC",
		Sizes: "Available Size: S, M, L, XL, XXXL",
		Collar: "Collar Type: NA",
		minimunOrderQuantity: "Menimum Order Quantity: NA",
		Technics: "Technics: ABC,EFG,XYZ",
		Season: "NA",
		fabricType: "Fabric Type: NA",
		Material: "Material: NA",
		Customised: "Customized? YES",
		description:
			"Embrace timeless style and everyday comfort with our premium sweatshirt, thoughtfully designed for versatility and performance. Crafted from a superior blend of soft cotton and durable polyester, this sweatshirt offers the ideal combination of warmth and breathability, making it perfect for any occasion—from lounging at home to braving chilly outdoor adventures. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "Soft, durable cotton-polyester blend",
			customDesign: "Relaxed, ergonomic fit for comfort and mobility",
			vibrantPrints: "Soft, High-Quality Fabric for ultimate comfort.",
			unisexFit: "Add your own text, logos, or artwork.",
			multipleColors: "High-quality, durable prints that won’t crack or fade.",
			reinforcedStitching: " Comfortable fit for all body types",
			ecoFriendlyPrinting: " Choose from a variety of color options to match your style.",
			lightweightAndStretchable: "Durable stitching for long-lasting wear.",
			fit: "Uses eco-conscious ink and processes.",
			customDesign:
				"Customizable Options to add your unique touch, including colors, prints, and embroidery.",
		},
		imageUrl: [
			require("../Images/Products/Sweat shirt/XPIC18/main-shirt.png"),
			require("../Images/Products/Sweat shirt/XPIC18/6.png"),
			require("../Images/Products/Sweat shirt/XPIC18/7.png"),
			require("../Images/Products/Sweat shirt/XPIC18/22.png"),
			require("../Images/Products/Sweat shirt/XPIC18/23.png"),
			require("../Images/Products/Sweat shirt/XPIC18/24.png"),
		],
		button: "Enquire",
	},
	{
		id: 154,
		name: "Sweat Shirt 19",
		price: "Price : $39.99",
		category: "Sweat Shirt",
		productionLeadTime: "Production Lead Time: ABC",
		Sizes: "Available Size: S, M, L, XL, XXXL",
		Collar: "Collar Type: NA",
		minimunOrderQuantity: "Menimum Order Quantity: NA",
		Technics: "Technics: ABC,EFG,XYZ",
		Season: "NA",
		fabricType: "Fabric Type: NA",
		Material: "Material: NA",
		Customised: "Customized? YES",
		description:
			"Embrace timeless style and everyday comfort with our premium sweatshirt, thoughtfully designed for versatility and performance. Crafted from a superior blend of soft cotton and durable polyester, this sweatshirt offers the ideal combination of warmth and breathability, making it perfect for any occasion—from lounging at home to braving chilly outdoor adventures. If you have any questions or require additional information, please feel free to reach out to us. Our knowledgeable team, with its extensive experience, is dedicated to offering you the best support possible. We are committed to addressing your needs promptly and effectively, ensuring you receive the highest level of service and satisfaction.",
		Feature: {
			hightQualityFabric: "Soft, durable cotton-polyester blend",
			customDesign: "Relaxed, ergonomic fit for comfort and mobility",
			vibrantPrints: "Soft, High-Quality Fabric for ultimate comfort.",
			unisexFit: "Add your own text, logos, or artwork.",
			multipleColors: "High-quality, durable prints that won’t crack or fade.",
			reinforcedStitching: " Comfortable fit for all body types",
			ecoFriendlyPrinting: " Choose from a variety of color options to match your style.",
			lightweightAndStretchable: "Durable stitching for long-lasting wear.",
			fit: "Uses eco-conscious ink and processes.",
			customDesign:
				"Customizable Options to add your unique touch, including colors, prints, and embroidery.",
		},
		imageUrl: [
			require("../Images/Products/Sweat shirt/ZZPIC19/6.png"),
			require("../Images/Products/Sweat shirt/ZZPIC19/7.png"),
			require("../Images/Products/Sweat shirt/ZZPIC19/25.png"),
			require("../Images/Products/Sweat shirt/ZZPIC19/26.png"),
			require("../Images/Products/Sweat shirt/ZZPIC19/27.png"),
			require("../Images/Products/Sweat shirt/ZZPIC19/28.png"),
		],
		button: "Enquire",
	},
];

export default products;
