import React from "react";
import Enquire from "../Components/Enquire/Enquire";
import data from "../Components/Assets/Data/data";
import { useParams } from "react-router-dom";

const EnquirePage = () => {
	const { productId } = useParams();
	const enquireData = data.find((e) => e.id === parseInt(productId));

	return (
		<>
			<Enquire enquireData={enquireData} />
		</>
	);
};

export default EnquirePage;
