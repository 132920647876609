import React, { useEffect, useState } from "react";
import Display from "../Components/Display/Display";
import data from "../Components/Assets/Data/data";
import ReactPaginate from "react-paginate";

const Shop = () => {
	const [currentPage, setCurrentPage] = useState(0);
	const itemsPerPage = 15;

	const filteredProducts = data;

	const handlePageClick = (event) => {
		setCurrentPage(event.selected);
	};

	const offset = currentPage * itemsPerPage;
	const currentItems = filteredProducts.slice(offset, offset + itemsPerPage);

	const [shuffledProducts, setShuffledProducts] = useState([]);

	useEffect(() => {
		// Function to shuffle the array
		const shuffleArray = (array) => {
			let newArray = [...array]; // Copy the array
			for (let i = newArray.length - 1; i > 0; i--) {
				const j = Math.floor(Math.random() * (i + 1));
				[newArray[i], newArray[j]] = [newArray[j], newArray[i]];
			}
			return newArray;
		};

		// Shuffle products and set to state
		setShuffledProducts(shuffleArray(data));
	}, []);

	return (
		<>
			<Display filteredProducts={(currentItems, shuffledProducts)} />

			{/* <ReactPaginate
				previousLabel={"previous"}
				nextLabel={"next"}
				pageCount={Math.ceil(filteredProducts.length / itemsPerPage)}
				onPageChange={handlePageClick}
				containerClassName={"pagination"}
				activeClassName={"active"}
			/> */}
		</>
	);
};

export default Shop;
