import React from "react";
import "../Carousel/Carousel.css";

const Carousel = () => {
	return (
		<>
			<div id="carouselExampleInterval" class="carousel slide" data-bs-ride="carousel">
				<div id="carousel" class="carousel-inner mb-5">
					<div class="carousel-item active" data-bs-interval="10000">
						<img
							src="https://images.unsplash.com/photo-1462392246754-28dfa2df8e6b?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
							class="d-block w-100 object-fit-cover "
							alt="..."
						/>
					</div>
					<div class="carousel-item" data-bs-interval="2000">
						z{" "}
						<img
							src="https://images.unsplash.com/photo-1529220355416-122440146f39?q=80&w=1503&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
							class="d-block w-100 object-fit-cover "
							alt="..."
						/>
					</div>
					<div class="carousel-item">
						<img
							src="https://images.unsplash.com/photo-1529998648927-793aacd33d1e?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
							class="d-block w-100 object-fit-cover"
							alt="..."
						/>
					</div>
				</div>
				<button
					class="carousel-control-prev"
					type="button"
					data-bs-target="#carouselExampleInterval"
					data-bs-slide="prev"
				>
					<span class="carousel-control-prev-icon" aria-hidden="true"></span>
					<span class="visually-hidden">Previous</span>
				</button>
				<button
					class="carousel-control-next"
					type="button"
					data-bs-target="#carouselExampleInterval"
					data-bs-slide="next"
				>
					<span class="carousel-control-next-icon" aria-hidden="true"></span>
					<span class="visually-hidden">Next</span>
				</button>
			</div>
		</>
	);
};

export default Carousel;
