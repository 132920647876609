import React from "react";
import Brand from "../Components/Brand/Brand";

const About = () => {
	return (
		<>
			<Brand />
		</>
	);
};

export default About;
