import React from "react";
import Detail from "../Components/Detail/Detail";
import data from "../Components/Assets/Data/data";
import { useParams } from "react-router-dom";
import Feature from "../Components/Feature/Feature";

const ProductDetail = () => {
	const { productId } = useParams();
	const productData = data.find((e) => e.id === parseInt(productId));

	return (
		<>
			<Detail productData={productData} />
			<Feature />
		</>
	);
};

export default ProductDetail;
