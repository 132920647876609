import React from "react";
import "../Item/Item.css";
import data from "../Assets/Data/data";
import { Link } from "react-router-dom";

const Item = (props) => {
	return (
		<>
			<div className="Item">
				<div className="product-grid d-flex gap-4 justify-content-center mt-5">
					<div key={props.id} className="product-card">
						<Link to={`/ProductDetail/${props.id}`}>
							<img loading="lazy" onClick={window.scrollTo(0, 0)} src={props.url[0]} alt={props.name} />
						</Link>
						<h5 className="mt-3 mx-4 text-center">{props.name}</h5>
						<p>${props.price}</p>

						<Link to={`/EnquirePage/${props.id}`}>
							<button className="btn btn-dark w-100">{props.button}</button>
						</Link>
					</div>
				</div>
			</div>
		</>
	);
};

export default Item;
