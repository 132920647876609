import React from "react";
import "../Feature/Feature.css";
import data from "../Assets/Data/feature";
import { Link } from "react-router-dom";

const Feature = () => {
	return (
		<>
			<div className="Display d-flex justify-content-center">
				<div
					id="grid"
					className="container product-grid d-flex gap-4 justify-content-center mt-5 flex-wrap"
				>
					{data.map((product) => (
						<div key={product.id} className="product-card">
							<Link to={`/ProductDetail/${product.id}`}>
								<img
									loading="lazy"
									onClick={window.scrollTo(0, 0)}
									src={product.imageUrl[0]}
									alt={product.name}
								/>
							</Link>
							<h5 className="mt-3 mx-4 text-center">{product.name}</h5>
							<p>{product.price}</p>

							<Link to={`/EnquirePage/${product.id}`}>
								<button className="btn btn-dark w-100">{product.button}</button>
							</Link>
						</div>
					))}
				</div>
			</div>
		</>
	);
};

export default Feature;
