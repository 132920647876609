import React, { useState } from "react";
import "../Display/Display.css";
import products from "../Assets/Data/data";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";

const Display = ({ filteredProducts }) => {
	const [searchTerm, setSearchTerm] = useState("");

	const displayedProducts = filteredProducts.filter((product) =>
		product.name.toLowerCase().includes(searchTerm.toLowerCase())
	);

	return (
		<div className="Display">
			<input
				value={searchTerm}
				onChange={(e) => setSearchTerm(e.target.value)}
				class="form-control me-2"
				type="search"
				placeholder="Hoodies, T Shirts, Printed, Shorts..."
				aria-label="Search"
			/>
			<div className="product-display product-grid container d-flex flex-wrap gap-4 justify-content-center my-4">
				{displayedProducts.length > 0 ? (
					displayedProducts.map((product) => (
						<div key={product.id} className="product-card text-center justify-content-between">
							<Link to={`/ProductDetail/${product.id}`}>
								<img onClick={window.scrollTo(0, 0)} src={product.imageUrl[0]} alt={product.name} />
							</Link>
							<h5 className="mt-2 mx-4 text-center">{product.name}</h5>
							<p>${product.price}</p>

							<Link to={`/EnquirePage/${product.id}`}>
								<button className="btn btn-dark w-100">{product.button}</button>
							</Link>
						</div>
					))
				) : (
					<p>No products found</p>
				)}
			</div>
		</div>
	);
};

export default Display;
