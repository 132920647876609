import React from "react";
import { Link } from "react-router-dom";
import "../NavBar/Navbar.css";

const Navbar = () => {
	return (
		<nav class="navbar navbar-expand-lg border-bottom border-body" data-bs-theme="dark">
			<div class="container-fluid">
				<Link className="text-decoration-none text-white" to={"/"}>
					<h5 className="brand-name">BEAOXOGO</h5>
				</Link>
				<button
					class="navbar-toggler"
					type="button"
					data-bs-toggle="collapse"
					data-bs-target="#navbarColor01"
					aria-controls="navbarColor01"
					aria-expanded="false"
					aria-label="Toggle navigation"
				>
					<span class="navbar-toggler-icon"></span>
				</button>
				<div class="collapse navbar-collapse" id="navbarColor01">
					<ul class="d-flex justify-content-end w-100 navbar-nav me-5 mb-2 mb-lg-0">
						<li class="nav-item">
							<Link to={"/"}>Home</Link>
						</li>
						<li class="nav-item">
							<Link to={"/Shop"}>Shop</Link>
						</li>
						<li class="nav-item">
							<Link to={"/Contact"}>Contact</Link>
						</li>
						<li class="nav-item">
							<Link to={"/About"}>About</Link>
						</li>
					</ul>
				</div>
			</div>
		</nav>
	);
};

export default Navbar;
