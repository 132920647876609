import React from "react";
import "../Footer/Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faPinterest, faThreads } from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
	return (
		<>
			<footer class=" d-flex flex-wrap justify-content-between py-2 border-top">
				<div className="container d-flex align-items-center">
					<p class="col-md-4 mb-0 text-white copyright">© 2024 Company, Inc</p>

					<div class="col-md-4 d-flex align-items-center justify-content-center mb-3 mb-md-0 me-md-auto link-body-emphasis text-decoration-none"></div>

					<ul class="nav col-md-4 justify-content-end">
						<li class="nav-item">
							<a href="#" target="_blank" class="text-white nav-link px-2">
								<FontAwesomeIcon icon={faInstagram} size="2x" />
							</a>
						</li>
						<li class="nav-item">
							<a href="#" target="_blank" class="text-white nav-link px-2">
								<FontAwesomeIcon icon={faPinterest} size="2x" />
							</a>
						</li>
						<li class="nav-item">
							<a href="#" target="_blank" class="text-white nav-link px-2">
								<FontAwesomeIcon icon={faThreads} size="2x" />
							</a>
						</li>
					</ul>
				</div>
			</footer>
		</>
	);
};

export default Footer;
