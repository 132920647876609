import React from "react";
import { Link } from "react-router-dom";
import Hero from "../Components/Hero/Hero";
import Category from "../Components/Category/Category";
import Feature from "../Components/Feature/Feature";
import Form from "../Components/Form/Form";
import Carousel from "../Components/Carousel/Carousel";

const Home = () => {
	return (
		<>
			<Hero />
			<h1 className="text-center mt-2">Category Section</h1>
			<hr />
			<Category />
			<h1 className="text-center mt-2">Feature Products</h1>
			<hr />
			<Feature />
			<hr />
			<Form />
			<Carousel />
		</>
	);
};

export default Home;
