import React from "react";
import "../Hero/Hero.css";
import { Link } from "react-router-dom";

const Hero = () => {
	return (
		<>
			<div class="container-fluid overflow-hidden Hero">
				<div class="d-flex justify-content-center align-items-center ">
					<div class="content d-flex flex-column justify-content-start  ">
						<h1 class="display-4 fw-bold lh-1  mb-4 text-center ">
							Manufacturer Of Apparel <br /> and Sportswear
						</h1>

						<div class="d-grid gap-2 d-md-flex justify-content-center mb-4 mb-lg-3 mt-4">
							{/* <button type="button" class="btn btn-dark w-75 btn-lg px-4 me-md-2 fw-bold">
								SHOP NOW
							</button> */}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Hero;
