import React from "react";
import "../Form/Form.css";

const Form = () => {
	return (
		<>
			<div class="container col-xl-10 col-xxl-8 px-4 py-5">
				<div class="row align-items-center g-lg-5 py-5">
					<div class="col-lg-7 text-center text-lg-start">
						<h1 class="display-4 fw-bold lh-1 text-body-emphasis mb-3">
							Vertically centered hero sign-up form
						</h1>
						<p class="col-lg-10 fs-4">
							Below is an example form built entirely with Bootstrap’s form controls. Each required form
							group has a validation state that can be triggered by attempting to submit the form without
							completing it.
						</p>
					</div>
					<div class="col-md-10 mx-auto col-lg-5">
						<form
							action="https://formsubmit.co/murtazahmed001@gmail.com"
							method="POST"
							class="p-4 p-md-5 border rounded-3 bg-body-tertiary"
						>
							<input type="hidden" name="form-name" value="contact-1" />

							<div class="form-floating mb-3">
								<input
									name="username"
									required
									type="text"
									class="form-control"
									id="floatingInput"
									placeholder="Name"
								/>
								<label for="floatingInput">Name</label>
							</div>
							<div class="form-floating mb-3">
								<input
									name="email"
									type="email"
									class="form-control"
									id="floatingInput2"
									placeholder="Email"
								/>
								<label for="floatingInput2">Email</label>
							</div>

							<button class="w-100 btn btn-lg btn-primary" type="submit">
								Sign Up
							</button>
							<hr class="my-4" />
							<small class="text-body-secondary">
								By clicking Sign up, you agree to the terms of use.
							</small>
						</form>
					</div>
				</div>
			</div>
		</>
	);
};

export default Form;
