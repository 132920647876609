import React from "react";
import img from "../Components/Assets/Images/Hero1.jpg";

const Contact = () => {
	return (
		<>
			<div class="container flex-column justify-content-start my-5">
				<h2 class="mb-3 mx-2 bg-body-secondary w-100 px-4 py-1">info@beaoxogo.com</h2>
				<div class="d-flex justify-content-center align-items-center">
					<div className="col-lg-6 ">
						<img
							onClick={window.scrollTo(0, 0)}
							className="w-100 h-100 px-lg-4 object-fit-cover"
							src={img}
							alt=""
						/>
					</div>
					<div class="col-lg-6 mx-2">
						<form
							name="contact-2"
							action="https://formsubmit.co/murtazahmed001@gmail.com"
							method="POST"
							className="contact-f"
						>
							<input type="hidden" name="form-name" value="contact-2" />
							<div class="row g-3">
								<div class="col-md-6">
									<label for="your-name" class="form-label">
										Your Name
									</label>
									<input type="text" class="form-control" id="your-name" name="your-name" required />
								</div>
								<div class="col-md-6">
									<label for="your-surname" class="form-label">
										Your Surname
									</label>
									<input type="text" class="form-control" id="your-surname" name="your-surname" required />
								</div>
								<div class="col-md-6">
									<label for="your-email" class="form-label">
										Your Email
									</label>
									<input type="email" class="form-control" id="your-email" name="your-email" required />
								</div>
								<div class="col-md-6">
									<label for="your-subject" class="form-label">
										Your Subject
									</label>
									<input type="text" class="form-control" id="your-subject" name="your-subject" />
								</div>
								<div class="col-12">
									<label for="your-message" class="form-label">
										Your Message
									</label>
									<textarea
										class="form-control"
										id="your-message"
										name="your-message"
										rows="5"
										required
									></textarea>
								</div>
								<div class="col-12">
									<div class="row">
										<div class="col-md-6 w-100">
											<button data-res="<?php echo $sum; ?>" type="submit" class="btn btn-dark w-100 fw-bold">
												Send
											</button>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);
};

export default Contact;
